import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Form, Modal } from 'react-bootstrap'
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdbreact'

import LoginContext from '../../../context/login/login.context'
import Navbar from '../navbar/navbar.component'
import axiosClient from '../../../config/axios'
import VerboseAlert from '../../verbose-alert/verbose-alert.component'

const RegisterSchedule = ({ history }) => {
    const loginContext = useContext(LoginContext)
    const { user, currentEvent } = loginContext

    const [userId, setUserId] = useState()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [alert, setAlert] = useState(false)
    const [loadingSchedule, setLoadingSchedule] = useState(false)

    const minimunAppointments = Math.round(6)

    // dates
    const [eventDates, setEventDates] = useState({})
    const [loadingEventDates, setLoadingEventDates] = useState(true)

    const handleChange  = async (e) => { 
        const { selectedIndex } = e.target.options
        const dateId = e.target.options[selectedIndex].id
        const response = await axiosClient.get('/api/event/get-event-hours', 
                                                { params: { dateId: dateId, eventId: currentEvent } } )
        setFilteredSchedule(response.data.data)
    }

    // schedule
    const [schedule, setSchedule] = useState({})
    const [filteredSchedule, setFilteredSchedule] = useState(false)
    const [completedHours, setCompletedHours] = useState(0)

    const handleSchedule = e => {
        const { id, checked } = e.target
        setSchedule({ ...schedule, [id]: checked })
    }

    const submitSchedule = async () => {
        try {
            setLoadingSchedule(true)
            let data = []

            for (let key in schedule) {
                if (schedule[key]) {
                    data.push([currentEvent, userId ,Number(key)])
                }
            }

            const response = await axiosClient.post('/api/users/add-schedule', data)
            setLoadingSchedule(false)
            setShow(false)
            setAlert(response.data.message)
            setCompletedHours(0)
            localStorage.removeItem('schedule')
            history.push('/citas')
        } catch (error) {
            console.log(error.response)
        }
    }

    const getEventDates = async () => {

        const response = await axiosClient.get(
            '/api/event/get-event-dates',
            { params: { eventId: currentEvent }}
        )
        
        let dates = response.data.data

        // clean iso format
        for (let key in dates) {
            dates[key].fecha = dates[key].fecha.slice(0, 10)
        }
 
        setEventDates(dates)
        setLoadingEventDates(false)
    }

    const alreadyWithSchedule = async () => {
        const response = await axiosClient.get('/api/users/get-schedule', 
        { params: { userId: user.id, checkRegister: true, eventId: currentEvent } } )
        if (response.data.message === 1) {
            setAlert("Ya cuentas con agenda, nos vemos pronto.")
            history.push('/citas')
        }
    }

    useEffect(() => {
        if (Object.keys(schedule).length > 0) {
            const count = []
            for (const key in schedule) {
                if (schedule[key] === true) count.push(schedule[key])
            }
            setCompletedHours(count.length)
        }

        if (filteredSchedule) {
            const storageSchedule = JSON.stringify(schedule)
            localStorage.setItem('schedule', storageSchedule)
        }
        // eslint-disable-next-line
    }, [schedule])

    useEffect(() => {
        if (user) {
            if (user.id_tipo_empresa === 1){
                history.push('/stands')
            }
            setUserId(user.id)
            alreadyWithSchedule()
        } 
        getEventDates()
        const json = localStorage.getItem('schedule')
        const storagedSchedule = JSON.parse(json)
        if (storagedSchedule) {
            setSchedule(storagedSchedule)
        }
    }, [])

    return (
        <Fragment>
            <Helmet 
                title="Registrar agenda"
            />
            <Navbar />
            <section className="mt-5">
                <MDBContainer>
                    {alert ? (
                        <VerboseAlert 
                            color="success"
                            title="Agenda Aprobada"
                            content={alert}
                        />
                    ) : (
                        <Fragment>
                            <h3><strong>Elije los días y horarios en que estarás disponible para la atención a proveedores.
                            </strong></h3>
                            <h5>Citas minimas requeridas: <strong>{ minimunAppointments }</strong></h5>
                            <h5>Citas completadas al momento: <strong>{ completedHours }</strong></h5>
                        </Fragment>
                    )}
                </MDBContainer>
            </section>
            <section>
                <MDBContainer className="mt-5">
                    {!alert ? (
                        <MDBRow>
                            <MDBCol sm="12" md="6">
                                <div className="form-group">
                                    <label htmlFor="date"><strong>Fechas</strong></label>
                                    <select 
                                        name="date"
                                        className="form-control"
                                        id="date"
                                        onChange={handleChange}
                                    >
                                    <option value="">-- Selecciona fecha --</option>
                                    {loadingEventDates ? null : (
                                        eventDates.map(date => (
                                            <option 
                                                id={date.id}
                                                key={date.id}
                                            >
                                                {date.fecha}
                                            </option>
                                        ))
                                    )}
                                    </select>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    ) : null}
                    { filteredSchedule && !alert ? (
                        <MDBTable
                            responsive
                        >
                            <MDBTableHead>
                                <tr>
                                    <th>Hora</th>
                                    <th>Disponible</th>
                                </tr>
                                {filteredSchedule.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.horario}</td>
                                        <td>
                                            <Form.Check 
                                                id={item.id}
                                                type="checkbox"
                                                checked={schedule[item.id] ? 'checked' : ''}
                                                onChange={handleSchedule}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableHead>
                            <MDBTableBody>
                            </MDBTableBody>
                        </MDBTable>
                    ) : null }
                    {completedHours >= minimunAppointments ? (
                        <button
                            onClick={handleShow}
                            className="btnd btn-b"
                            id="save"
                        >
                            Guardar
                        </button>
                    ) : null}
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirmación de agenda
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { loadingSchedule ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <p>Una vez confirmada la agenda no habrán cambios.</p>
                    ) }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        id="accept-appointment" 
                        className="btnd btn-b" 
                        onClick={submitSchedule}>
                        Aceptar
                    </button>
                    <button className="btnd btn-c" onClick={handleClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>

    );
}
 
export default RegisterSchedule;