import React from 'react'
import { MDBAlert } from 'mdbreact'

const Alert = ({ color, message }) => {
    return (
        <MDBAlert color={color}>
            { message }
        </MDBAlert>
    );
}
 
export default Alert;