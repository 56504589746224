import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import { 
    MDBContainer, 
    MDBRow,
    MDBCol
} from 'mdbreact'

import Alert from '../../alert/alert.component'
import AdminNav from '../navbar/navbar.component'
import AxiosClient from '../../../config/axios'

const Event = () => {
    const [event, setEvent] = useState({
        eventType: '',
        startDate: '',
        endingDate: '',
        logo: '',
        name: '',
        description: ''
    })

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () =>  {
        setShow(true);
        setAlertSuccess(false)
    }
    
    const [eventTypes, setEventTypes] = useState({})
    const [loadingEventTypes, setLoadingEventTypes] = useState(true)
    
    const [alert, setAlert] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)

    const [loadingEvent, setLoadingEvent] = useState(false)

    const handleChange = e => {
        if (e.target.files) {
            setEvent({
                ...event,
                [e.target.name]: e.target.files[0]
            })
        } else {
            const { name, value } = e.target
            
            setEvent({
                ...event,
                [name]: value
            })
        }
    }

    const [data, setData] = useState([])
    
    const handleSubmit = async e => {
        e.preventDefault()
        const { eventType, startDate, endingDate, logo, name, description } = event

        if (eventType === "" || startDate === "" || endingDate === "" || logo === "" ||
            name === "" || description === "") {
            setAlert(true)
            return;
        } else {
            try {
                setLoadingEvent(true)
                setAlert(false)
                const data = new FormData()
                data.append('eventType', eventType)
                data.append('startDate', startDate)
                data.append('endingDate', endingDate)
                data.append('brandEvent', logo)
                data.append('name', name)
                data.append('description', description)
                console.log(logo)
                const response = await AxiosClient.post('/api/event/add-event', data )
                setShow(false)
                setLoadingEvent(false)
                setAlertSuccess(response.data.message)
            } catch (error) {
                console.log(error.response)
            }

            // const newEvent = { 
            //     eventType: eventType,
            //     name: name,
            //     startDate: startDate,
            //     endingDate: endingDate,
            //     participants: '1',
            //     state: <MDBAlert color="success" >Activo</MDBAlert>
            // }
            // setData([...data, newEvent])
            // setShow(false)
        }
    }

    const getEvenTypes = async () => {
        const response = await AxiosClient.get('/api/event/get-event-types')
        setEventTypes(response.data.data)
        setLoadingEventTypes(false)
    }

    // const getEvents = async () => {
    //     const response = await AxiosClient.get('/api/event/get-events')
    //     console.log(response.data.data)
    // }

    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: 'Id',
            field: 'id',
        },
        {
            label: 'Tipo de Evento',
            field: 'eventType',
        },
        {
            label: 'Nombre',
            field: 'name',
        },
        {
            label: 'Inicia',
            field: 'startDate',
        },
        {
            label: 'Finaliza',
            field: 'endingDate',
        },
        {
            label: 'Participantes',
            field: 'participants',
        },
        {
            label: 'Estado',
            field: 'state',
        },
        ],
    })

    useEffect(() => {
        getEvenTypes()
        // getEvents()
        // setDatatable({        
        //     ...datatable,
        //     rows: data
        // })

    }, [data])

    return(
        <Fragment>
            <Helmet
                title="Eventos"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Eventos</strong></h4>
                    <br></br>
                    <button className="btnd btn-b mb-3" onClick={handleShow}>
                        Agregar
                    </button>
                    { alertSuccess ? (
                        <Alert color="success" message={alertSuccess} />
                    ) : null }
                    
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <form 
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Registrar Evento
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { alert ? (
                            <Alert color="danger" message="Todos los campos son obligatorios" />
                        ) : null }

                        { loadingEvent ? (
                            <div className="text-center mb-2">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null }
                        <MDBRow mt="4">
                            <MDBCol sm="12">
                                <div className="form-group">
                                    <label htmlFor="eventType"><strong>Tipo de Evento</strong></label>
                                    <select 
                                        className="form-control" 
                                        id="eventType"
                                        name="eventType"
                                        onChange={handleChange}
                                    >
                                        <option value="">-- Seleccionar --</option>
                                        { loadingEventTypes ? null : (
                                            eventTypes.map(eventType => (
                                                <option 
                                                    key={eventType.id}
                                                    value={eventType.id}
                                                >  
                                                    { eventType.nombre }
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="startDate"><strong>Fecha de Inicio</strong></label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        name="startDate"
                                        onChange={handleChange}    
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="endingDate"><strong>Fecha de Fin</strong></label>
                                    <input 
                                        type="date" 
                                        className="form-control"
                                        name="endingDate" 
                                        onChange={handleChange}    
                                    />
                                </div>
                                <div className="custom-file form-group mb-3">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        name="logo"
                                        onChange={handleChange}
                                    />
                                    <label className="custom-file-label">
                                        Logo de evento
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Nombre"
                                        name="name"    
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            placeholder="Descripcion"
                                            name="description"    
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                </Modal.Body>
                <Modal.Footer>
                    <input type="submit" value="Guardar" className="btnd btn-b" />
                </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    );
}

export default Event;


// <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} /> linea 172