import types from './login.types'

const reducer = (state, action) => {
    switch (action.type) {
        case types.LOG_IN_SUCCESS:
            localStorage.setItem('token', action.payload)
            return {
                ...state,
                token: action.payload,
                authenticated: false,
                loading: false,
                message: null
            }
        case types.LOG_IN_ERROR:
            return {
                ...state,
                authenticated: false,
                token: null,
                message: action.payload,
                loading: false,
                isAdmin: false
            }
        case types.GET_USER:
            if (action.payload.id_tipo_usuario === 1) {
                return {
                    ...state,
                    user: action.payload,
                    authenticated: true,
                    isAdmin: true,
                    loading: false
                }
            }
            return {
                ...state,
                user: action.payload,
                authenticated: true,
                loading: false,
                isAdmin: false,
            }
        case types.UPDATE_CURRENT_EVENT:
            return {
                ...state,
                logo: action.payload[0].logo,
                currentEvent: action.payload[0].id
            } 
        case types.UPDATE_CURRENT_EVENT_ERROR:
            return {
                ...state,
                message: action.payload
            }
        case types.LOG_OUT:
            localStorage.removeItem('token')
            return {
                ...state,
                user: null,
                authenticated: false,
                loading: false,
                token: null,
                isAdmin: false,
                logo: null,
                currentEvent: null
            }
        case types.LOADING_LOGIN:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}

export default reducer