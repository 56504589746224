import React, { useReducer } from 'react'

import axiosClient from '../../config/axios'
import authToken from '../../config/token'

import types from './login.types'
import reducer from './login.reducer'
import LoginContext from './login.context'

const initialState = {
    token: localStorage.getItem('token'),
    isAdmin:false,
    authenticated: false,
    user: null,
    loading: false,
    message: null,
    currentEvent: null,
    logo: null
}

const LoginState = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const logIn = async (data) => {
        try {
            dispatch({ type: types.LOADING_LOGIN })

            const response = await axiosClient.post('/api/users/', data)
            dispatch({
                type: types.LOG_IN_SUCCESS,
                payload: response.data.token
            })

            getUser()
        } catch (error) {
            dispatch({
                 type: types.LOG_IN_ERROR,
                 payload: error.response.data.message
            })
        }
    }

    const getUser = async () => {
        const token = localStorage.getItem('token')
        if (token) authToken(token)
        
        try {
            const response = await axiosClient.get('/api/users')
            dispatch({
                type: types.GET_USER,
                payload: response.data.data
            })
        } catch (error) {
            dispatch({
                type: types.LOG_IN_ERROR
            })
        }
    }

    const logOut = () => {
        dispatch({
            type: types.LOG_OUT
        })
    }
    
    const switchEvent = async (id) => {
        const token = localStorage.getItem("token")
        if (token) authToken(token)
        
        try {
            const response = await axiosClient.get("api/event/get-logo", { params: { id: id } })    
            dispatch({
                type: types.UPDATE_CURRENT_EVENT,
                payload: response.data.data
            })
        } catch (error) {
            dispatch({
                type: types.UPDATE_CURRENT_EVENT_ERROR,
                payload: error.response.data.message
            })
        }
    }

    return (
        <LoginContext.Provider
            value={{
                isAdmin: state.isAdmin,
                authenticated: state.authenticated,
                user: state.user,
                message: state.message,
                currentEvent: state.currentEvent,
                logo: state.logo,
                loading: state.loading,
                logIn,
                logOut,
                getUser,
                switchEvent,
                // loadingLogin,
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}

export default LoginState