import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import { 
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBDataTableV5,
    MDBBadge,
    MDBAlert
} from 'mdbreact'

import Navbar from '../navbar/navbar.component'
import VerboseAlert from '../../verbose-alert/verbose-alert.component'
import LoginContext from '../../../context/login/login.context'
import Alert from '../../alert/alert.component'
import axiosClient from '../../../config/axios'
import './appointment.styles.css'


const Appointment = ({ history }) => {
    const [alertSuccess, setAlertSuccess] = useState(false)

    const [alertErrorSchedule, setAlertErrorSchedule] = useState(false)

    const [buyers, setBuyers] = useState({})
    const [loadingBuyers, setLoadingBuyers] = useState(true)

    const [alertBuyers, setAlertBuyers] = useState(false)
    
    const [alertError, setAlertError] = useState(false)
    const [compatibility, setCompatibility] = useState(false)

    const [appointments, setAppointments] = useState({})

    const loginContext = useContext(LoginContext)
    const { user, currentEvent } = loginContext


    // recommended buyersa
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)


    const [dataRecommendation, setDataRecommendation] = useState({})
    const [loadingRecommendation, setLoadingRecommendation] = useState(true)
    const handleShow = async () => {

        try{
            let response = ""
            if(user.id_tipo_empresa === 3){
                response = await axiosClient.get('/api/appointment/query-buyer-recommendation', 
                                            { params: { 
                                                idEnterprise: user.id_empresa, 
                                                idEvent: currentEvent
                                                } 
                                            } )
            }
            else{
                response = await axiosClient.get('/api/appointment/query-provider-recommendation', 
                                            { params: { 
                                                idEnterprise: user.id_empresa, 
                                                idEvent: currentEvent
                                                } 
                                            } )
            }
            setAlertBuyers(false)
            setDataRecommendation(response.data.data)
            setLoadingRecommendation(false)
            if(response.data.data.length === 0) setAlertBuyers(true)
            setShow(true)
        } catch (error){
            console.log(error.response)
            setCompatibility(false)
            setAlertErrorSchedule(error.response.data.message)
            setProducts({})
            setDatatable({
                ...datatable,
                rows: []
            })
        }
    }

    // appointments
    const [alert, setAlert] = useState(false)
    const [showAppointment, setShowAppointment] = useState(false)
    const [validateRequest, setValidateReuquest] = useState(false)
    const handleCloseAppointment = () => setShowAppointment(false)
    const handleShowAppointment = (e) => {
        setAlert(false)
        setShowAppointment(true)
        setTimeout(() => {
            const btn = document.getElementById('accept-appointment')
            btn.setAttribute('appointmentid', e.target.attributes.appointmentid.value)
        }, 500);
    }
    const [filter, setFilter] = useState({})

    const [products, setProducts] = useState({})

    const handleClick = async e => {

        try{ 
            const idUser = e.target.attributes.value.value
            const idBuyer = e.target.attributes.id.value
            setFilter({
                buyerName: e.target.attributes.buyername.value,
                idBuyer: idBuyer,
                idUser: idUser
            })
            
            document.querySelector(`#buyer [value='${idUser}'`).selected = true;
            setShow(false)
            setAlertBuyers(false)
            if(user.id_tipo_empresa === 3){
                let response = await axiosClient.get('/api/appointment/query-buyer',
                                     { params: { idEvent: currentEvent,
                                                 idUser: idUser, 
                                                 idEnterprise: user.id_empresa, 
                                                 idBuyer: idBuyer
                                                } 
                                    } )
                response = response.data.data
                for (let i = 0; i < response.result.length; i++) {
                    if(response.result[i].id_estado === 3){
                        response.result[i].schedule = <MDBAlert color="primary" >En Proceso</MDBAlert>
                    } else{
                        response.result[i].schedule = 
                                <button appointmentid={response.result[i].id} 
                                        className="btnd btn-b" 
                                        onClick={handleShowAppointment}>
                                            Agendar
                                </button>
                    }
                }
    
                setCompatibility(true)
                setAlertError(false)
                setProducts({
                    numProducts: response.numProducts,
                    numProductsBuyer: response.numProductsBuyer,
                    compatibleProducts: response.compatibleProducts
                })
                setDatatable({
                    ...datatable,
                    rows: response.result
                })            
            }
            else{
                
                let response = await axiosClient.get('/api/appointment/query-provider',
                                     { params: { idEvent: currentEvent,
                                                 idUser: idUser, 
                                                 idEnterpriseBuyer: user.id_empresa, 
                                                 idProvider: idBuyer
                                                } 
                                    } )
                
                response = response.data.data
                for (let i = 0; i < response.arr.length; i++) {
                    if(response.arr[i].id_estado === 3){
                        response.arr[i].schedule = <MDBAlert color="primary" >En Proceso</MDBAlert>
                    } else{
                        response.arr[i].schedule = <button appointmentid={response.arr[i].id} className="btnd btn-b" onClick={handleShowAppointment}>Agendar</button>
                    }
                }

                setCompatibility(true)
                setAlertError(false)

                setProducts({
                    numProducts: response.numProducts,
                    numProductsBuyer: response.numProductsBuyer,
                    compatibleProducts: response.compatibleProducts
                })
                setDatatable({
                    ...datatable,
                    rows: response.arr
                })
            }
        } catch (error){
            setTimeout(() => {
                setCompatibility(false)
                setAlertErrorSchedule(error.response.data.message)
                setProducts({})
                setDatatable({
                    ...datatable,
                    rows: []
                })
            }, 1000);
        }
    }

    const handleFilter = async e => {
        const { name, value, options } = e.target
        setAlert(false)
        setAlertError(false)
        setCompatibility(true)

        if (value === '-- Selecciona compradora --' || value === '-- Selecciona proveedora --') {
                setAlertError(true)
                setCompatibility(false)
                setDatatable({
                    ...datatable,
                    rows: []
                })
                return 
        } else {
            try {

                const buyerName = options[options.selectedIndex].text
                setFilter({
                    buyerName: buyerName,
                    [name]: value
                })

                getAppointments(value, options[options.selectedIndex].id)
            } catch (error) {
                console.log(error.response)
            }
        }
            
    }

    const [data, setData] = useState([])
    const getAppointments = async (value, idCompradora) => {

        try{
            setAlertErrorSchedule(false)
            let response = ""
            if(user.id_tipo_empresa === 3){
                response = await axiosClient.get('/api/appointment/query-buyer', 
                                                { params: { 
                                                    idEvent: currentEvent, 
                                                    idUser: value, 
                                                    idEnterprise: user.id_empresa, 
                                                    idBuyer: idCompradora 
                                                } } )
            }
            else{
                response = await axiosClient.get('/api/appointment/query-provider', 
                                                { params: { 
                                                    idEvent: currentEvent, 
                                                    idUser: value, 
                                                    idEnterpriseBuyer: user.id_empresa, 
                                                    idProvider: idCompradora 
                                                } } )
            }
            if(user.id_tipo_empresa === 3){
                
                response = response.data.data
                for (let i = 0; i < response.result.length; i++) {
                    if(response.result[i].id_estado === 3 && response.result[i].idEmpresaProveedora === user.id){
                        response.result[i].schedule = <MDBAlert color="primary" >En Proceso</MDBAlert>
                        
                    } 
                    else if(response.result[i].id_estado === 3 && response.result[i].idEmpresaProveedora !== user.id){
                        delete response.result[i]
                        
                    }
                    else{
                        response.result[i].schedule = <button appointmentid={response.result[i].id} className="btnd btn-b" onClick={handleShowAppointment}>Agendar</button>
                    }
                }
                setProducts({
                    numProducts: response.numProducts,
                    numProductsBuyer: response.numProductsBuyer,
                    compatibleProducts: response.compatibleProducts
                })
                setDatatable({
                    ...datatable,
                    rows: response.result
                })
            }
            else{
                response = response.data.data
                for (let i = 0; i < response.arr.length; i++) {
                    if(response.arr[i].id_estado === 3){
                        response.arr[i].schedule = <MDBAlert color="primary" >En Proceso</MDBAlert>
                    } else{
                        response.arr[i].schedule = <button appointmentid={response.arr[i].id} className="btnd btn-b" onClick={handleShowAppointment}>Agendar</button>
                    }
                }

                setProducts({
                    numProducts: response.numProducts,
                    numProductsBuyer: response.numProductsBuyer,
                    compatibleProducts: response.compatibleProducts
                })
                setDatatable({
                    ...datatable,
                    rows: response.arr
                })
            }
            
        } catch (error){
            setTimeout(() => {
                setCompatibility(false)
                setAlertErrorSchedule(error.response.data.message)
                setProducts({})
                setDatatable({
                    ...datatable,
                    rows: []
                })
            }, 1000);
        }
    }

    const handleAppointment = async (e) => {
        
        setAlertErrorSchedule(false)
        setValidateReuquest(true)
        setCompatibility(true)
        const appointmentId = e.target.attributes.appointmentid.value

        var combo = document.getElementById("buyer");
        var selected = combo.options[combo.selectedIndex];

        if(user.id_tipo_empresa === 3){
            try {
                const newAppointment = {
                    idEnterprise: user.id_empresa,
                    schedule: appointmentId,
                    flag: false,
                }
                const response = await axiosClient.post('/api/appointment/schedule', newAppointment)

                setTimeout(() => {
                    setValidateReuquest(false)
                    setShowAppointment(false)
                    setAlert(true)
                    getAppointments(selected.value, selected.id)
                }, 1000);

            } catch (error) {
                setTimeout(() => {
                    setValidateReuquest(false)
                    setShowAppointment(false)
                    setAlertErrorSchedule(error.response.data.message)
                }, 1000);
            }
            
        }
        else{
            try {
                const newAppointmentBuyer = {
                    idEnterprise: selected.value,
                    schedule: appointmentId,
                    flag: true,
                }
                const response = await axiosClient.post('/api/appointment/schedule', newAppointmentBuyer)
               

                setTimeout(() => {
                    setValidateReuquest(false)
                    setShowAppointment(false)
                    setAlert(true)
                    getAppointments(selected.value, selected.id)
                }, 1000);
            } catch (error) {
                setTimeout(() => {
                    setValidateReuquest(false)
                    setShowAppointment(false)
                    setAlertErrorSchedule(error.response.data.message)
                }, 1000);
            }
            
        }
        
    }


    const getBuyers = async () => {
        let flagBuyer = false
        if(user.id_tipo_empresa === 2) flagBuyer = true
        const response = await axiosClient.get('/api/enterprise/get-buyers', 
                                            {params: { idEvent: currentEvent, flagBuyer: flagBuyer } })
        setBuyers(response.data.data)
        setDatatable({
            ...datatable,
            rows: []
        })
        setLoadingBuyers(false)
    }

    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: 'Fecha',
                field: 'date',
            },
            {
                label: 'Hora',
                field: 'hour',
            },
            {
                label: 'Agendar',
                field: 'schedule',
            },
        ],
    })

    const { buyer, buyerName } = filter

    const { numProducts, numProductsBuyer, compatibleProducts } = products

    useEffect(() => {
        if (user.id_tipo_empresa === 1)
        history.push('/stands')
        getBuyers()
        // eslint-disable-next-line
    }, [data, currentEvent])

    return (
        <Fragment>
            <Helmet
                title="Citas disponibles"
            />
            <Navbar />
            <section className="mt-5">
                <MDBContainer>
                    <form>
                        <MDBRow>
                            <MDBCol sm="12" md="12">
                                <div className="form-group">
                                    <center><h2><strong>Encuentro de negocios</strong></h2></center>
                                </div> 
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" md="6">
                                <div className="form-group">
                                    <label htmlFor="buyer"><strong>{ user.id_tipo_empresa === 3 ? ("Compradoras") : ("Proveedores") }</strong></label>
                                    <select 
                                        name="buyer" 
                                        className="form-control" 
                                        id="buyer"
                                        onChange={handleFilter}
                                    >
                                    { user.id_tipo_empresa === 3 ? 
                                        (<option>-- Selecciona compradora --</option>) 
                                        : (<option>-- Selecciona proveedora --</option>) }
                                        
                                       
                                        { loadingBuyers ? null : (
                                            buyers.map((buyer) => (
                                                <option 
                                                    key={buyer.idUsuario}
                                                    value={buyer.idUsuario} 
                                                    id={buyer.id}
                                                >
                                                    {buyer.nombre}
                                                </option>
                                        )))}
                                    </select>
                                </div>  
                            </MDBCol>
                        </MDBRow>
                    </form>
                    <button 
                        className="btnd btn-b"
                        onClick={handleShow}
                    >
                        { user.id_tipo_empresa === 2 ? 
                            ("Proveedores Recomendados") 
                            : ("Compradoras recomendadas") }
                        
                    </button>
                </MDBContainer>
            </section>
            <section>
                <MDBContainer>
                {buyer !== '0' ? (
                    <Fragment>
                        { alert ? (
                            <VerboseAlert color="success" title="Solicitud enviada" content="La solicitud quedara en proceso hasta que el administrador la acepte" />
                        ) : null }
                        
                        <br></br>
                        {alertSuccess ? (
                            <Alert color="success" message={alertSuccess}  />
                        ) : null }
                        { alertError ? (
                            <Alert color="danger" message="Debe seleccionar una empresa compradora" />
                        ) : null }
                        { alertErrorSchedule ? (
                            <Alert color="danger" message={alertErrorSchedule} />
                        ) : null }
                        { compatibility ? (
                            <Fragment>
                                <h5 className="mt-5">
                                    <strong>
                                        { user.id_tipo_empresa === 2 ? 
                                        ("Mis Fechas Disponibles") 
                                        : "Fechas Disponibles de: " } 
                                    </strong>
                                    { user.id_tipo_empresa === 2 ? null : buyerName } 

                                    <MDBBadge 
                                        pill 
                                        color="danger" 
                                        className="ml-2"
                                    >

                                    { numProducts > 0 ?
                                        (<Fragment>
                                            { numProducts } 
                                            { numProducts === 1 ? " Producto Compatible de " : " Productos Compatibles de "}
                                            { numProductsBuyer }
                                            {/*20% de compatibilidad*/}
                                        </Fragment>)
                                    : "No Existen Productos Compatibles"
                                    }
                                    </MDBBadge>
                                </h5>
                                { numProducts === 0 ? null : <strong>Los Productos Compatibles Son: </strong> }
                                { numProducts === 0 ? null : compatibleProducts }
                            </Fragment>
                        ) : null }
                        {/* <p>Cuentas con 
                            <strong> probabilidad baja </strong>
                            de concretar un encuentro con { buyerName }
                        </p> */}
                        <MDBDataTableV5 
                            hover 
                            responsive
                            entriesOptions={[5, 20, 25]} 
                            entries={5} 
                            pagesAmount={4} 
                            data={datatable} 
                        />
                    </Fragment>
                ) : null }
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { user.id_tipo_empresa === 3 ? (
                            "Compradoras Recomendadas"
                        ) : ("Proveedores Recomendados") }
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="modal" name="modal">
                    { alertBuyers ? (
                        <Alert color="danger" message="No exiten recomendaciones" />
                    ) : null }
                    
                    { loadingRecommendation ? null : (
                        dataRecommendation.map((recommendation) => (
                        <p 
                        onClick={handleClick} 
                        className={"d-flex justify-content-between align-items-center buyer-recomendation"}
                        value={recommendation.idUser}
                        buyerName={recommendation.buyer}
                        id={recommendation.idBuyer}
                        >
                            {recommendation.buyer}
                            <MDBBadge pill color={"success"} value={recommendation.idUser} buyerName={recommendation.buyer} id={recommendation.idBuyer}>{recommendation.products}{" productos compatibles de "}{recommendation.productsTotals}</MDBBadge>
                        </p>
                    )))}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnd btn-c" onClick={handleClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
            
            <Modal  show={showAppointment} onHide={handleCloseAppointment}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirmación de citas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { validateRequest ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <p>Esta realizando una solicitud para una cita, una vez que la solicitud sea validada y aprobada por el administrador el status cambiara a aceptada, ¿Desea realizar la Solicitud?</p>
                    ) }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        id="accept-appointment" 
                        className="btnd btn-b" 
                        onClick={handleAppointment}>
                        Aceptar
                    </button>
                    <button className="btnd btn-c" onClick={handleCloseAppointment}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
 
export default Appointment;