import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Modal, Accordion, Card } from 'react-bootstrap'
import { MDBContainer} from 'mdbreact'

import AdminNav from '../navbar/navbar.component'

const Schedule = () => {
    const [schedule, addSchedule] = useState({})
    
    const handleChange = e => {
        const { name, value } = e.target
        addSchedule({
            ...schedule,
            [name]: value
        })
    }

    const handleSubmitQuery = () => {
        if(document.getElementById("horarios").value !== "Seleccionar"){
            console.log("Exito!")
        }
        else{
            alert("Debe Seleccionar un Evento")
        }
    }

    const [data, setData] = useState([])
    const handleSubmit = () => {
        const { event, date, hour } = schedule
        //console.log(Object.keys(schedule).length)
        if(Object.keys(schedule).length < 3){
            alert("Debe Ingresar todos los datos.")
            return;
        } else {
            if(event === "" || event === "Seleccionar"){
                alert("Debe Seleccionar un Evento.")
                return;
            } else if(date === "" || date === "Seleccionar"){
                alert("Debe Seleccionar una Fecha.")
                return;
            } else if(hour === ""){
                alert("Debe Ingresar una Hora.")
                return;
            } else {
                const addHour = { 
                    event: event,
                    date: date,
                    hour: hour
                }
                setData([...data, addHour])
                setShow(false)
            }
        }
        
    }

    const [filterEvent, setFilterEvent] = useState('')
    const handleFilterEvent = e => {
        setFilterEvent(e.target.value)
    }
    
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    

    return(
        <Fragment>
            <Helmet
                title="Horarios"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Horarios</strong></h4>
                    <br></br>
                    <button className="btnd btn-b" onClick={handleShow}>
                        Agregar
                    </button>
                    <br></br>
                    <br></br>
                    <h4><strong>Horarios de Evento</strong></h4>
                    <div className="form-group">
                        <select 
                            className="form-control col-sm-12 col-md-6" 
                            id="horarios"
                            name="event"
                            onChange={handleFilterEvent}
                        >
                            <option>Seleccionar</option>
                            <option>Evento 1</option>
                            <option>Evento 2</option>
                            <option>Evento 3</option>
                            <option>Evento 4</option>
                            <option>Evento 5</option>
                        </select>
                        <br></br>
                        <button className="btnd btn-b" onClick={handleSubmitQuery}>
                            Buscar
                        </button>
                    </div>
                    <Accordion className="mt-4">
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                Fecha 1
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                </ul>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                Fecha 2
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <ul>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                    <li>Hora </li>
                                </ul>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> 
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Registrar Horario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="event"><strong>Evento</strong></label>
                            <select 
                                className="form-control" 
                                id="event"
                                name="event"
                                onChange={handleChange}
                            >
                                <option>Seleccionar</option>
                                <option>Evento 1</option>
                                <option>Evento 2</option>
                                <option>Evento 3</option>
                                <option>Evento 4</option>
                                <option>Evento 5</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="date"><strong>Fecha</strong></label>
                            <select 
                                className="form-control" 
                                id="fecha"
                                name="date"
                                onChange={handleChange}
                            >
                                <option>Seleccionar</option>
                                <option>Fecha 1</option>
                                <option>Fecha 2</option>
                                <option>Fecha 3</option>
                                <option>Fecha 4</option>
                                <option>Fecha 5</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <input 
                                type="text" 
                                id="hour" 
                                className="form-control" 
                                placeholder="Hora"
                                name="hour"
                                onChange={handleChange}
                            ></input>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnd btn-b" onClick={handleSubmit} >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );

}

export default Schedule;