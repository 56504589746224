import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { 
    MDBContainer, 
    MDBDataTableV5, 
} from 'mdbreact'
import { Modal } from 'react-bootstrap'

import AdminNav from '../navbar/navbar.component'
import Alert from '../../alert/alert.component'
import AxiosClient from '../../../config/axios'
import LoginContext from '../../../context/login/login.context'


const User = () => {
    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext
    const [show, setShow] = useState(false)
    const [user, addUser] = useState({})

    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        setAlertSuccess(false)
        setAlertCorreo(false)
    }

    const [enterprises, setEnterprises] = useState({})
    const [loadingEnterprises, setLoadingEnterprises] = useState(true)

    const [alert, setAlert] = useState(false)
    const [alertCorreo, setAlertCorreo] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)

    const [loadingUser, setLoadingUser] = useState(false)

    const handleChange = e => {
        const { name, value } = e.target
        addUser({
            ...user,
            [name]: value
        })
    }

    const handleNotActive = e => {
        e.target.parentNode.parentNode.parentNode.children[5].innerHTML="<div data-test=\"alert\" class=\"alert alert-danger\" role=\"alert\">No</div>"
        alert("usuario desactivado")
    }


    const handleActive = e => {
        e.target.parentNode.parentNode.parentNode.children[5].innerHTML="<div data-test=\"alert\" class=\"alert alert-success\" role=\"alert\">Si</div>"
        alert("usuario Activado")
    }

    const [data, setData] = useState([])
    
    const handleSubmit = async () => {
        if(Object.keys(user).length < 4){
            setAlert(true)
            setAlertCorreo(false)
            return;
        } else {
            try {
                user.event = currentEvent
                setLoadingUser(true)
                setAlert(false)
                setAlertCorreo(false)
                const response = await AxiosClient.post('/api/users/add-user', user)
                console.log(response.data.message)
                setAlertSuccess(response.data.message)
                setShow(false)
                setLoadingUser(false)
                getUsers()
            } catch (error) {
                setLoadingUser(false)
                setAlertCorreo(error.response.data.message)
                //setAlert(error.response.data.errors[0].msg)
            }

            // if(email !== ""){
            //     var re=/^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
            //     if(!re.exec(email)){
            //         alert('email no valido');
            //     }
            //     else{
            //         const addUser = { 
            //             id: idNew,
            //             enterprise: enterprise,
            //             name: name,
            //             email: email,
            //             actions: <MDBBtnGroup size="sm">
            //                         <MDBBtn color="success" id={idNew} onClick={handleActive}>Activar</MDBBtn>
            //                         <MDBBtn color="danger" id={idNew} onClick={handleNotActive}>Desactivar</MDBBtn>
            //                     </MDBBtnGroup>,
            //             active: <MDBAlert color="success" >Si</MDBAlert>
            //         }
            //         setData([...data, addUser])
            //         setShow(false)
            //     }
            // }
        }
    }


    const getEnterprises = async () => {
        const response = await AxiosClient.get('/api/enterprise/get-enterprise', 
                                                { params: { flagUsers: true, eventId: currentEvent }}
        )
        setEnterprises(response.data.data)
        setLoadingEnterprises(false)
    }

    const getUsers = async () => {
        const response = await AxiosClient.get('/api/users/get-users', { params: { eventId: currentEvent } })
        setDatatable({
            ...datatable,
            rows: response.data.data
        })
    }

    const [datatable, setDatatable] = useState({        
        columns: [
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Nombre',
                field: 'usuario',
            },
            {
                label: 'Empresa',
                field: 'empresa',
            },
            {
                label: 'Correo',
                field: 'correo',
            },

            // {
            //     label: 'Acciones',
            //     field: 'actions',
            // },
            // {
            //     label: 'Activo',
            //     field: 'cuenta_activa',
            // },
        ],
    })

    useEffect(() => {
        if (currentEvent) {
            getEnterprises()
            getUsers()
        }
        // setDatatable({        
        //     ...datatable,
        //     rows: data
        // })

    }, [currentEvent])

    return (
        <Fragment>
            <Helmet
                title="Agregar usuarios"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Usuarios</strong></h4>
                    <button className="btnd btn-b mb-3" onClick={handleShow}>
                        Agregar
                    </button>
                    { alertSuccess ? (
                        <Alert color="success" message={alertSuccess} />
                    ) : null }
                    <MDBDataTableV5 
                        responsive 
                        hover 
                        entriesOptions={[5, 20, 25]} 
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable} 
                    />
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agregar usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { alert ? (
                        <Alert color="danger" message="Todos los datos son obligatorios" />
                    ) : null }
                    { alertCorreo ? (
                        <Alert color="danger" message={alertCorreo} />
                    ) : null }

                    { loadingUser ? (
                        <div className="text-center mb-2">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null }

                    <div className="form-group">
                        <label htmlFor="enterprise"><strong>Empresa</strong></label>
                        <select 
                            className="form-control"
                            id="enterprise"
                            name="enterprise"
                            onChange={handleChange}
                        >
                            <option value="">-- Seleccionar --</option>
                            { loadingEnterprises ? null : (
                                enterprises.map(enterprise => (
                                    <option
                                        value={enterprise.id}
                                        key={enterprise.id}
                                    >
                                        {enterprise.nombre}
                                    </option>
                                ))
                            ) }
                        </select>
                    </div> 
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="name" 
                            id="name"
                            className="form-control" 
                            placeholder="Nombre"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input 
                            type="email" 
                            name="email" 
                            id="email"
                            className="form-control" 
                            placeholder="Correo"
                            onChange={handleChange}
                            />
                    </div>
                    <div className="form-group">
                        <input 
                            type="password" 
                            name="password" 
                            id="password"
                            className="form-control"
                            placeholder="Password" 
                            onChange={handleChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnd btn-b" onClick={handleSubmit}>
                        Guardar
                    </button>
                    <button className="btnd btn-c" onClick={handleClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
 
export default User;