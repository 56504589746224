import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link, Redirect } from 'react-router-dom'

import FormContainer from '../users/form-container/form-container.component'
import Alert from '../alert/alert.component'
import './login.styles.css'

import LoginContext from '../../context/login/login.context'

const Login = ({ history }) => {
    const [signUpVisitor, setSignUpVisitor] = useState()
    const [alert, setAlert] = useState(false)
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    
    const loginContext = useContext(LoginContext)
    const { 
        logIn,
        authenticated, 
        message, 
        isAdmin, 
        loading, 
    } = loginContext

    const handleChange = e => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setAlert(false)
        const { email, password } = data

        if (email === '' || password === '') {
            setAlert(true)
            return
        }

        logIn(data)
    }

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSignUpVisitor(history.location.state.message)
            history.location.state.message = undefined
        }
    }, [])

    return (
        <Fragment>
            <Helmet
                title="Iniciar sesión"
            />
            <FormContainer>
                {signUpVisitor !== undefined ? ( 
                    <Alert  color="success" message={signUpVisitor} /> 
                    ) : null 
                }
                { alert ? (
                    <Alert color="danger" message="Todos los campost son obligatorios." />
                ) : null}
                { message ? (
                    <Alert color="warning" message={message} />
                ) : null}
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="form-group">
                        <input 
                            name="email" 
                            type="email" 
                            className="form-control" 
                            placeholder="Correo"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input 
                            name="password" 
                            type="password" 
                            className="form-control" 
                            placeholder="Contraseña"
                            onChange={handleChange}
                        />
                    </div>
                    {loading ? (
                        <button
                            className="btnd btn-b"
                            disabled
                        >
                            Espere...
                            <div className="spinner-border spinner-border-sm ml-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    ) : (
                        <input
                            type="submit"
                            value="Iniciar Sesión"
                            className="btnd btn-b"
                        />
                    )}

                    {!isAdmin && authenticated && <Redirect to="/formulario-datos" /> }
                    {isAdmin && <Redirect to="/agregar-usuario" /> }
                </form>  
                <p className="mt-3">
                    ¿Eres visitante?
                    <span>
                        <br/>
                        <Link to="/registro-visitante"><strong>Regístrate aquí</strong></Link>
                    </span>
                </p>
                <br/>
                <Link to="/recuperar-contraseña"><strong>¿Olvidaste tú contraseña?</strong></Link>
            </FormContainer>
        </Fragment>
    );
}
 
export default Login;