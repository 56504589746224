import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { 
    MDBContainer, 
    MDBDataTableV5,
    MDBRow,
    MDBCol
} from 'mdbreact'

import AdminNav from '../navbar/navbar.component'
import AxiosClient from '../../../config/axios'
import { generatePdf } from '../../../config/helper'

import LoginContext from '../../../context/login/login.context'


const ScheduleEnterprise = () => {
    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const [enterprises, setEnterprises] = useState({})
    const [loadingEnterprises, setLoadingEnterprises] = useState(true)

    const [enterprise, setEnterprise] = useState()

    const getEnterprises = async () => {
        try {
            setDatatable({
                ...datatable,
                rows: []
            })
            const response = await AxiosClient.get('/api/enterprise/get-enterprise',
                                                { params: { flagBuyers: true, eventId: currentEvent }}
            )
            setEnterprises(response.data.data)
            setLoadingEnterprises(false)   
        } catch (error) {
            console.log(error.response)
        }
    }

    const [data, setData] = useState([])
    const handleExport = () => {
        if(data.length === 0){
            alert("no ha seleccionado ninguna Agenda")
        } else {
            alert("EXITO!")
        }
    }

    const handleChange = async () => {
        setData([])
        if(document.getElementById("enterprise").value === ""){
            setDatatable({rows:[]})
        } else {
            try {
                const idEnterprise = document.getElementById("enterprise").value
                const enterpriseName = document.getElementById("enterprise").selectedOptions[0].text
                setEnterprise(enterpriseName)

                const response = await AxiosClient.get('/api/users/get-schedule-enterprise', 
                                                { params: { idEnterprise: idEnterprise } })
                setDatatable({
                    ...datatable,
                    rows: response.data.data
                })
            } catch (error) {
                console.log(error.response)
            }
        }
    } 
    
    useEffect(() => {
        getEnterprises()        
    }, [currentEvent])

    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: 'Id',
            field: 'id',
        },
        {
            label: 'Proveedora',
            field: 'nombre',
        },
        {
            label: 'Fecha',
            field: 'fecha',
        },
        {
            label: 'Hora',
            field: 'horario',
        },
    ],
        
    })

    return(
        <Fragment>
            <Helmet
                title="Agenda de Empresas"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4 className="mb-3"><strong>Agendas de Empresas</strong></h4>
                    <MDBRow>
                        <MDBCol sm="12" md="6">
                            <div className="form-group">
                                <select 
                                    className="form-control" 
                                    id="enterprise"
                                    name="enterprise"
                                >
                                    <option value="">-- Seleccionar empresa --</option>
                                    { loadingEnterprises ? null : (
                                        enterprises.map(enterprise => (
                                            <option
                                                value={enterprise.id}
                                                key={enterprise.id}
                                                onClick={handleChange}
                                            >
                                                {enterprise.nombre}
                                            </option>
                                        ))
                                    ) }
                                </select>
                            </div>
                        </MDBCol>
                        <MDBCol sm="12" md="6">
                            {enterprise ? (
                                <button 
                                    className="btnd btn-b"
                                    onClick={() => generatePdf(datatable, enterprise)}
                                >
                                    Exportar agenda
                                </button>
                            ) : null}
                        </MDBCol>
                    </MDBRow>
                    <br></br>
                    <MDBDataTableV5 
                        responsive
                        hover 
                        entriesOptions={[5, 20, 25]} 
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable} 
                    />
                </MDBContainer>
            </section>
        </Fragment>
    );
}

export default ScheduleEnterprise;