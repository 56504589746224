import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import Login from './components/login/login.component'
import RecoverPassword from './components/users/recover-password/recover-password.component'
import SignUpVisitor from './components/users/sign-up-visitor/sign-up-visitor.component'

import UserDataForm from './components/users/user-data-form/user-data-form.component'
import UserData from './components/users/user-data/user-data.component'
import MySchedule from './components/users/schedule/schedule.component'
import Appointment from './components/users/appointment/appointment.component'
import RegisterSchedule from './components/users/register-schedule/register-schedule.component'
import DigitalDirectory from './components/users/digital-directory/digital-directory.component'
import Stand from './components/users/stands/stand.component'
import StandListing from './components/users/stands/stands-listing.component'
import UploadStandData from './components/users/stands/upload-stand-data.component'

import User from './components/admin/users/users.component'
import Enterprise from './components/admin/enterprise/enterprise.component'
import ScheduleEnterprise from './components/admin/schedule-enterprise/schedule-enterprise.component'
import Event from './components/admin/event/event.component'
import UserEvent from './components/admin/user-event/userEvent.component'
import Schedule from './components/admin/schedule/schedule.component'
import Request from './components/admin/request/request.component'
import Sectors from './components/admin/sectors/sectors.component'
import StreamingRequest from './components/admin/streaming-request/streaming-request.component'
import RegisterScheduleStreaming from './components/admin/register-schedule-streaming/registerScheduleStreaming.component'
import Products from './components/admin/products/products.component'
import StandsAdmin from './components/admin/stands/stands-admin.component'

import LoginState from './context/login/login.state'

import PrivateRouteUser from './components/users/private-route-user/private-route-user'
import PrivateRouteAdmin from './components/admin/private-route-admin/private-route-admin'

import authToken from './config/token';

const token = localStorage.getItem('token')

if (token) {
  authToken(token)
}

function App() {


  return (
    <LoginState>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/recuperar-contraseña" component={RecoverPassword} />
          <Route path="/registro-visitante" component={SignUpVisitor} />
          
          <PrivateRouteUser exact path="/formulario-datos" component={UserDataForm} />
          <PrivateRouteUser exact path="/mis-datos" component={UserData} />
          <PrivateRouteUser exact path="/mi-agenda" component={MySchedule} />
          <PrivateRouteUser exact path="/citas" component={Appointment} />
          <PrivateRouteUser exact path="/registro-agenda" component={RegisterSchedule} />
          <PrivateRouteUser exact path="/mi-stand-virtual" component={UploadStandData} />
          <PrivateRouteUser exact path="/directorio" component={DigitalDirectory} />
          <PrivateRouteUser exact path="/stands" component={StandListing} />
          <PrivateRouteUser exact path="/stand/:slug" component={Stand} />

          <PrivateRouteAdmin exact path="/agregar-usuario" component={User} />
          <PrivateRouteAdmin exact path="/agregar-empresa" component={Enterprise} />
          <PrivateRouteAdmin exact path="/agenda-empresa" component={ScheduleEnterprise} />
          <PrivateRouteAdmin exact path="/agregar-evento" component={Event} />
          <PrivateRouteAdmin exact path="/registro-de-participantes" component={UserEvent} />
          <PrivateRouteAdmin exact path="/horarios" component={Schedule} />
          <PrivateRouteAdmin exact path="/solicitudes" component={Request} />
          <PrivateRouteAdmin exact path="/sectores" component={Sectors} />
          <PrivateRouteAdmin exact path="/solicitudes-transmisiones" component={StreamingRequest} />
          <PrivateRouteAdmin exact path="/registro-agenda-transmisiones" component={RegisterScheduleStreaming} />
          <PrivateRouteAdmin exact path="/productos" component={Products} />
          <PrivateRouteAdmin exact path="/stands-admin" component={StandsAdmin} />
        </Switch>
      </Router>
    </LoginState>
  );
}

export default App;
