import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { MDBContainer } from 'mdbreact'

import LoginContext from '../../../context/login/login.context'

import { cleanImage } from '../../../config/helper'

const AdminNav = () => {
    const loginContext = useContext(LoginContext)
    const { user, logOut, switchEvent, logo, currentEvent } = loginContext

    const [image, setImage] = useState()

    const getImage = () => {
        let logoImagen = cleanImage(logo)
        setImage(logoImagen)
    }

    const handleSwitchEvent = (e) => {
        switchEvent(e.target.id)
    }

    useEffect(() => {
        if (currentEvent === null) switchEvent(user.eventos[0].id)
    }, [])

    useEffect(() => {
        getImage()
    }, [logo])

    return (
        <Navbar className="nav-d" variant="dark" expand="md">
            <MDBContainer>
                <Navbar.Brand className="logo d-none d-md-block">
                    <Link to="/">
                        <img className="img-fluid mb-4" width="80" src={image} alt="logo" />
                    </Link> 
                </Navbar.Brand>
                <Navbar.Toggle abg="dark"ria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Fragment>
                            <NavDropdown title="Eventos" id="basic-nav-dropdown">
                                <NavDropdown
                                    title="Administrar"  
                                    id="basic-nav-dropdown"
                                    drop="left"
                                >
                                    <Fragment>
                                        { 
                                            user.eventos.map(item => (
                                                <Link 
                                                    id={item.id}
                                                    onClick={handleSwitchEvent}
                                                    className={item.id === currentEvent ? "drop-item active" : "drop-item" }
                                                >
                                                    {item.nombre}
                                                </Link>
                                            ))
                                        }
                                    </Fragment>
                                </NavDropdown>
                                <Link 
                                    to="/agregar-evento"
                                    className="drop-item"
                                >
                                    Agregar
                                </Link>
                                <Link 
                                    to="/solicitudes"
                                    className="drop-item"
                                >
                                    Solicitudes
                                </Link>
                            </NavDropdown>
                            <NavDropdown title="Empresas" id="basic-nav-dropdown">
                                <Link 
                                    to="/agregar-empresa" 
                                    className="drop-item"
                                >
                                        Agregar empresa
                                </Link>
                                <Link 
                                    to="/agenda-empresa" 
                                    className="drop-item"
                                >
                                        Agendas de empresa
                                </Link>
                                <Link 
                                    to="/stands-admin" 
                                    className="drop-item"
                                >
                                        Stands
                                </Link>
                                <Link 
                                    to="/sectores" 
                                    className="drop-item"
                                >
                                        Sectores
                                </Link>
                                <Link 
                                    to="/productos" 
                                    className="drop-item"
                                >
                                        Productos
                                </Link>
                            </NavDropdown>
                            <Link className="nav-element" to="/agregar-usuario">Usuarios</Link>
                        </Fragment>
                        <Link onClick={() => logOut() } className="nav-element">Salir</Link>
                    </Nav>
                </Navbar.Collapse>
            </MDBContainer>
        </Navbar>
    );
}
 
export default AdminNav;

