import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBIcon
} from 'mdbreact'

import LoginContext from '../../../context/login/login.context'
import Nav from '../navbar/navbar.component'
import axiosClient from '../../../config/axios'

import './digital-directory.styles.css'

const DigitalDirectory = () => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [sectors, setSectors] = useState({})
    const [loadingSectors, setLoadingSectors] = useState(true)

    const [enterprises, setEnterprises] = useState()
    const [loadingEnterprises, setLoadingEnterprises] = useState(false)

    const [enterpriseData, setEnterpriseData] = useState({})

    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const handleChange = async e => {
        setLoadingEnterprises(true)
        const sector = {[e.target.name]: e.target.value}
        const response = await axiosClient.get('/api/event/get-directory', { params: sector })
        setEnterprises(response.data.data)
        setLoadingEnterprises(false)
    }

    const showEnterprise = async e => {
        const response = await axiosClient.get('/api/enterprise/get-enterprise-directory',
                                                { params: { id: e.target.id } })
        setEnterpriseData(response.data.data[0])
        setShow(true)
    }

    const getSectors = async () => {
        const response = await axiosClient.get('/api/enterprise/get-sectors',
                                                { params: { flagDirectory: true, eventId: currentEvent } })
        setSectors(response.data.data)
        setLoadingSectors(false)
    }

    useEffect(() => {
        getSectors()
        setEnterprises()
    }, [currentEvent])

    return (
        <Fragment>
            <Helmet
                title="Directorio digital"
            />
            <Nav />
            <section className="mt-5">
                <MDBContainer>
                    <form action="">
                        <MDBRow>
                            <MDBCol sm="12" md="12">
                                <div className="form-group">
                                    <center><h2><strong>Directorio empresarial</strong></h2></center>
                                </div> 
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" md="6">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1"><strong>Sector</strong></label>
                                    <select 
                                        name="sector"
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        onChange={handleChange}
                                    >
                                        <option value="">-- Selecciona un sector --</option>
                                        { loadingSectors ? null : (
                                            sectors.map((sector) => (
                                                <option 
                                                    key={sector.id}
                                                    value={sector.id} 
                                                >
                                                    {sector.nombre}
                                                </option>
                                            )))
                                        }
                                    </select>
                                </div> 
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBContainer>
            </section>
            <section className="mt-5">
                <MDBContainer>
                    { enterprises ? (
                        <MDBCol sm="8" className="pl-0">
                            <MDBListGroup>
                                {enterprises.map(item => (
                                    <MDBListGroupItem 
                                        key={item.id}
                                        id={item.id}
                                        onClick={showEnterprise}
                                    >
                                        { item.empresa }
                                    </MDBListGroupItem>
                                ))}
                                </MDBListGroup>
                        </MDBCol>
                    ) : null}
                </MDBContainer>
            </section>
            { enterpriseData ? (
                <Modal className="data-directory" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {enterpriseData.nombre_empresa}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            <li><strong>Contacto:</strong> {enterpriseData.nombre}</li>
                            <li><strong>Télefono:</strong> {enterpriseData.whatsapp}</li>
                            <li><strong>Puesto:</strong> {enterpriseData.puesto}</li>
                        </ul>
                        <div className="text-center">
                            <h3>Informes y cotizaciones</h3>
                            <p>
                                <a 
                                    href={`https://api.whatsapp.com/send?phone=${enterpriseData.whatsapp}&text=Hola`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <MDBIcon className="mx-2" size="2x" fab icon="whatsapp"/>        
                                </a>
                                <a href={`mailto:${enterpriseData.correo}`}>
                                    <MDBIcon size="2x" icon="far fa-envelope"/>
                                </a> 
                            </p>
                            <hr/>

                            { enterpriseData.estado !== 3 ? (null) : (
                                <Fragment>
                                    <h3>Stand virtual</h3>
                                    <Link to={`/stand/${enterpriseData.slug}`}>
                                        <MDBIcon size="2x" icon="store-alt"/>
                                    </Link>
                                </Fragment>
                            )}

                            { enterpriseData.facebook || enterpriseData.twitter ? (
                                <Fragment>
                                    <h3 className="mt-3">Síguenos en Redes Sociales</h3>
                                    <p>
                                        {enterpriseData.facebook 
                                            ? (<a href={enterpriseData.facebook}
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                >
                                                <MDBIcon className="mx-2" size="2x" fab icon="facebook-f"/>
                                            </a>) 
                                            : null
                                        }
                                        {enterpriseData.twitter
                                            ? (<a 
                                                href={enterpriseData.twitter} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                >
                                                    <MDBIcon size="2x" fab icon="twitter"/>
                                                </a>)
                                            : null
                                        }
                                    </p>
                                </Fragment>
                            ) : null }
                        </div>
                    </Modal.Body>
                </Modal>
            ) : null }
        </Fragment>
    );
}
 
export default DigitalDirectory;