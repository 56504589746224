import React from 'react';
import { MDBContainer, MDBRow, MDBCol} from 'mdbreact'
import logo from '../../../images/logo150.png'

import './form-container.styles.css'

const FormContainer = ({children}) => {
    return (
        <MDBContainer className="text-center h-100">
            <MDBRow className="row h-100 justify-content-center align-items-center">
                <MDBCol className="login py-5" sm="12" md="7" lg="5">
                    <img className="img-fluid mb-4" width="150" src={logo} alt={logo} />
                    <MDBCol className="offset-sm-2" sm="8">
                        {children}
                    </MDBCol>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}
 
export default FormContainer;