import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'

import Navbar from '../navbar/navbar.component'
// import Products from '../products/products.component'

import axiosClient from '../../../config/axios'
import { cleanImage } from '../../../config/helper'

import './stands.styles.css'


const Stand = ( props ) => {
    const [data, setData] = useState({})
    const {slug} = props.match.params
    const {history} = props

    const getData = async () => {
        const response = await axiosClient.get('/api/users/get-stand', { params: { slug: slug } })
        const data = response.data.data[0]

        if (data.estado !== 3) history.push('/stands')
        console.log(data)
        
        if (data.imagen1) data.imagen1 = cleanImage(data.imagen1)
        if (data.imagen2) data.imagen2 = cleanImage(data.imagen2)
        if (data.imagen3) data.imagen3 = cleanImage(data.imagen3)
        if (data.imagen4) data.imagen4 = cleanImage(data.imagen4)
        if (data.imagen5) data.imagen5 = cleanImage(data.imagen5)
        if (data.catalogo) data.catalogo = cleanImage(data.catalogo)
        if (data.video) data.video = `https://www.youtube.com/embed/${data.video.slice(32)}`
        data.whatsapp = `https://api.whatsapp.com/send?phone=${data.whatsapp}&text= Hola!%20Me%20Podria%20Enviar%20Informacion!`
        data.correo = `mailto:"${data.correo}"`
        data.logo = cleanImage(data.logo)

        setData(data)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Fragment>
            <Helmet
                title="Stand Virtual"
            />
            <Navbar />
            <section className="data-section mt-5">
                <MDBContainer className="stand">
                    {data.logo
                        ? (
                            <MDBRow className="text-center">
                                <MDBCol className="sm-12">
                                    <img 
                                        width="30%"
                                        height="30%"
                                        src={data.logo} 
                                        className="img-fluid"
                                        alt="Logo no disponible"
                                    />
                                </MDBCol>
                            </MDBRow>
                        ) 
                        : null
                    }
                    {data.video
                        ? (
                            <MDBRow className="my-5">
                                <MDBCol className="sm-12 md-6">
                                    <iframe 
                                        title="video"
                                        width="560" 
                                        height="315" 
                                        src={data.video}
                                        frameborder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowfullscreen>
                                    </iframe>
                                </MDBCol>
                                <MDBCol dangerouslySetInnerHTML={{__html: data.descripcion}}  className="sm-12 md-6"/>
                            </MDBRow>
                        ) 
                        : <MDBRow className="my-5">
                            <MDBCol dangerouslySetInnerHTML={{__html: data.descripcion}}  className="sm-12 md-6"/>
                          </MDBRow>
                    }
                    <MDBRow className="mb-3">
                        { !data.imagen2 && !data.imagen3
                            ? (
                                <MDBCol className="sm-12 text-center">
                                    <img src={data.imagen1} className="img-fluid" alt=""></img>
                                </MDBCol>
                            )
                            : null
                        }
                        { data.imagen2 && data.imagen3
                            ? (
                                <Fragment>
                                    <MDBCol className="sm-12 md-4">
                                        <img src={data.imagen1} className="img-fluid" alt=""></img>
                                    </MDBCol>
                                    <MDBCol className="sm-12 md-4">
                                        <img src={data.imagen2} className="img-fluid" alt=""></img>
                                    </MDBCol>
                                    <MDBCol className="sm-12 md-4">
                                        <img src={data.imagen3} className="img-fluid" alt=""></img>
                                    </MDBCol>
                                </Fragment>
                            )
                            : null
                        }
                        {   !data.imagen2 && data.imagen3
                            ? (
                                <Fragment>
                                <MDBCol className="sm-12 md-6">
                                    <img src={data.imagen1} className="img-fluid" alt=""></img>
                                </MDBCol>
                                <MDBCol className="sm-12 md-6">
                                    <img src={data.imagen3} className="img-fluid" alt=""></img>
                                </MDBCol>
                                </Fragment>
                            )
                            : null
                        }
                        {   data.imagen2 && !data.imagen3
                            ? (
                                <Fragment>
                                <MDBCol className="sm-12 md-6">
                                    <img src={data.imagen1} className="img-fluid" alt=""></img>
                                </MDBCol>
                                <MDBCol className="sm-12 md-6">
                                    <img src={data.imagen2} className="img-fluid" alt=""></img>
                                </MDBCol>
                                </Fragment>
                            )
                            : null
                        }
                    </MDBRow>
                    <MDBRow className="mb-3">
                        {   !data.imagen4 && data.imagen5
                            ? (
                                <MDBCol className="sm-12 md-12">
                                    <img src={data.imagen5} className="img-fluid" alt=""></img>
                                </MDBCol>
                            )
                            : null
                        }
                        {   data.imagen4 && !data.imagen5
                            ? (
                                <MDBCol className="sm-12 md-12">
                                    <img src={data.imagen4} className="img-fluid" alt=""></img>
                                </MDBCol>
                            )
                            : null
                        }
                        {   data.imagen4 && data.imagen5
                            ? (
                                <Fragment>
                                    <MDBCol className="sm-12 md-6">
                                        <img src={data.imagen4} className="img-fluid" alt=""></img>
                                    </MDBCol>
                                    <MDBCol className="sm-12 md-6">
                                        <img src={data.imagen5} className="img-fluid" alt=""></img>
                                    </MDBCol>
                                </Fragment>
                            )
                            : null
                        }
                        
                    </MDBRow>
                    {data.catalogo 
                        ? (
                            <MDBRow className="my-4 text-center">
                                <MDBCol className="sm-12">
                                    <h4><strong>Conoce más Información Acerca de la Empresa {data.nombre}</strong></h4>
                                    <a 
                                        href={data.catalogo} 
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MDBIcon size="2x" far icon="file-pdf" />
                                    </a>
                                </MDBCol>
                            </MDBRow>
                        ) 
                        : null
                    }
                    {data.whatsapp || data.correo 
                        ? (
                            <MDBRow className="my-4 text-center">
                                <MDBCol className="sm-12">
                                    <h4><strong>Informes y cotizaciones</strong></h4>
                                    {data.whatsapp 
                                    ? (
                                        <a 
                                            href={data.whatsapp} 
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MDBIcon className="mx-2" size="2x" fab icon="whatsapp"/>
                                        </a>
                                    ) 
                                    : null}
                                    {data.correo 
                                    ? (
                                        <a 
                                            href={data.correo}
                                        >
                                            <MDBIcon size="2x" icon="far fa-envelope"/>
                                        </a>
                                    ) 
                                    : null}
                                </MDBCol>
                            </MDBRow>
                        ) 
                        : null
                    }
                    {data.facebook || data.twitter
                        ? (
                            <MDBRow className="my-4 text-center">
                                <MDBCol className="sm-12">
                                    <h4><strong>Síguenos en redes sociales</strong></h4>
                                    {data.facebook 
                                    ? (
                                        <a 
                                            href={data.facebook} 
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MDBIcon className="mx-2" size="2x" fab icon="facebook-f"/>
                                        </a>
                                    ) 
                                    : null}
                                    {data.twitter 
                                    ? (
                                        <a 
                                            href={data.twitter} 
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MDBIcon size="2x" fab icon="twitter"/>
                                        </a>
                                    ) 
                                    : null}
                                </MDBCol>
                            </MDBRow>
                        ) 
                        : null
                    }
                    {data.web
                        ? (
                            <MDBRow className="my-4 text-center">
                                <MDBCol className="sm-12">
                                <h4><strong>Visita nuestra página web</strong></h4>
                                    <a href={data.web} rel="noreferrer" target="_blank">{data.web}</a>
                                </MDBCol>
                            </MDBRow>
                        ) 
                        : null
                    }
                </MDBContainer>
            </section>
        </Fragment>
    );
}
 
export default Stand;