import React, { Fragment, useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import Navbar from "../navbar/navbar.component";
import Products from "../products/products.component";

import LoginContext from "../../../context/login/login.context";

import axiosClient from "../../../config/axios";
import { cleanImage } from "../../../config/helper";

const UserData = ({ history }) => {
  const loginContext = useContext(LoginContext);
  const { getUser, user, currentEvent } = loginContext;

  const [data, setData] = useState({});
  const [productsListing, setProductsListing] = useState({});
  const [loadingProducts, setLoadingProducts] = useState(true);

  const getData = async () => {
    const response = await axiosClient.get("/api/users/user-data", {
      params: { id: user.id },
    });

    const responseProducts = await axiosClient.get(
      "/api/enterprise//get-enterprise-products",
      {
        params: {
          enterpriseType: user.id_tipo_empresa,
          eventId: currentEvent,
          enterpriseId: user.id_empresa,
        },
      }
    );

    setProductsListing(responseProducts.data.data);

    let logo = response.data.data[0].logo;
    response.data.data[0].logo = cleanImage(logo);
    setData(response.data.data[0]);
    setLoadingProducts(false)
  };

  useEffect(() => {
    getUser();
    getData();
  }, []);

  if (user && user.id_empresa === 1) {
    history.push("/#/stands");
  }

  return (
    <Fragment>
      <Helmet title="Mis datos" />
      <Navbar />
      <section className="data-section mt-5">
        <MDBContainer>
          <MDBRow>
            <MDBCol sm="12" md="5" className="mb-3">
              <img
                width="80%"
                height="auto"
                src={data.logo}
                alt="logoEmpresa"
              />
            </MDBCol>
            <MDBCol sm="12" md="6">
              <h6>
                <strong>Datos de usuario:</strong>
              </h6>
              <p>Whatsapp: {data.whatsapp}</p>
              <p>Puesto: {data.puesto}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-4">
            <MDBCol sm="12" md="6">
              <h6>
                <strong>Datos de empresa:</strong>
              </h6>
              <MDBRow>
                <MDBCol sm="12" md="6">
                  <p>Razon social: {data.razon_social}</p>
                  <p>Domicilio: {data.domicilio}</p>
                </MDBCol>
                <MDBCol sm="12" md="6">
                  <p>RFC: {data.rfc}</p>
                  <p>Número de trabajadores: {data.numero_trabajadores}</p>
                  <p>Actividad principal: {data.actividad_principal}</p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            {loadingProducts ? (
              <div className="text-center mb-2">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <MDBCol sm="12" md="6">
                <div className="products pl-4" >
                  <MDBRow>
                    {productsListing.map((item) => (
                      <MDBCol sm="4">
                        <li>{item.nombre}</li>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </div>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </section>
    </Fragment>
  );
};

export default UserData;
