/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
    MDBContainer, 
    MDBDataTableV5,
    MDBAlert,
    MDBIcon
} from 'mdbreact'
import { Modal } from 'react-bootstrap'

import { cleanImage } from '../../../config/helper'
import axiosClient from '../../../config/axios'
import AdminNav from '../navbar/navbar.component'
import LoginContext from '../../../context/login/login.context'
import Alert from '../../alert/alert.component'

import './stands-admin.styles.css'

const StandsAdmin = () => {
    const [showStand, setShowStand] = useState(false)
    const handleCloseStand = () => {
        setShowStand(false)
        setStandData({})
    } 
    const handleShowStand = (e) => {
        setAlertSuccess(false)
        getStandData(e)
        setShowStand(true)
    } 

    const { currentEvent } = useContext(LoginContext)

    const [standData, setStandData] = useState({})
    const [comments, setComments] = useState()
    const [loadingComments, setLoadingComments] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [alert, setAlert] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)

    const handleChange = e => {
        setComments({
            [e.target.name]: e.target.value
        })
    }

    const getStands = async () => {
        try{
            const response = await axiosClient.get('/api/users/get-stands-admin', 
                                            { params: {eventId: currentEvent} })
            let data = response.data.data

            for (let i = 0; i < response.data.data.length; i++) {                
                data[i].visualizar = <button 
                                        id={data[i].id} 
                                        onClick={handleShowStand}
                                        className="btnd btn-i"
                                    >
                                        Visualizar
                                    </button>

                switch (data[i].estado) {
                    case 0:
                        data[i].estado = <MDBAlert color="warning">Sin datos</MDBAlert>
                        data[i].visualizar = 
                            <button 
                                id={data[i].id} 
                                onClick={handleShowStand}
                                className="btnd btn-i disabled"
                            >
                                Visualizar
                            </button>
                        break
                    case 1:
                        data[i].estado = <MDBAlert color="info">Pendiente de revisión</MDBAlert>
                        break
                    case 2:
                        data[i].estado = <MDBAlert color="danger">Datos inválidos</MDBAlert>
                        break
                    case 3:
                        data[i].estado = <MDBAlert color="success">Publicado</MDBAlert>
                        break
                    default:
                        break
                }
            }

            setDatatable({
                ...datatable,
                rows: data
            })
        } catch(error) {
            console.log(error.response)
        }
    }

    const getStandData = async e => {
        const response = await axiosClient.get('api/users/get-stand',
                                        { params: { enterpriseId: e.target.id } })
        const data = response.data.data[0]

        if (data.imagen1) data.imagen1 = cleanImage(data.imagen1)
        if (data.imagen2) data.imagen2 = cleanImage(data.imagen2)
        if (data.imagen3) data.imagen3 = cleanImage(data.imagen3)
        if (data.imagen4) data.imagen4 = cleanImage(data.imagen4)
        if (data.imagen5) data.imagen5 = cleanImage(data.imagen5)
        if (data.catalogo) data.catalogo = cleanImage(data.catalogo)
        if (data.video) data.video = `https://www.youtube.com/embed/${data.video.slice(32)}`
        data.whatsapp = `https://api.whatsapp.com/send?phone=${data.whatsapp}&text= Hola!%20Me%20Podria%20Enviar%20Informacion!`
        data.correo = `mailto:"${data.correo}"`
        data.logo = cleanImage(data.logo)

        setStandData(data)
    }

    const updateStandData = async e => {
        setAlert(false)
        const enterpriseId = e.target.attributes.enterpriseId.value
        const id = e.target.id
        const data = {
            id: id,
            enterpriseId: enterpriseId
        }
        
        let response = ''

        switch (id) {
            case "1":
                setLoadingComments(true)
                if (!comments) {
                    setAlert(true)
                    return
                } 
                
                data.comments = comments.comments

                response = await axiosClient.patch('api/users/update-stand-state', data)
                setLoadingComments(false)
                break
            default:
                setLoadingApprove(true)
                response = await axiosClient.patch('api/users/update-stand-state', data)
                setLoadingApprove(false)
                break
        }

        getStands()
        setAlertSuccess(response.data.message)
        setComments({})
        setShowStand(false)
    }

    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: '#',
            field: 'id',
        },
        {
            label: 'Empresa',
            field: 'nombre',
        },
        {
            label: 'Estado',
            field: 'estado',
        },
        {
            label: 'Visualizar',
            field: 'visualizar',
        },
    ],
        
    })

    useEffect(()  => {
        getStands()
    }, [currentEvent])
    
    return (
        <Fragment>
            <Helmet
                title="Stand Virtual Admin"
            />
            <AdminNav userType={1} />
            <section>
                <br></br><br></br>
                <MDBContainer>
                    <h4><strong>Stand Virtual</strong></h4>
                    {alertSuccess ? (
                        <Alert message={alertSuccess} color="success" />
                    ) : null}
                    <MDBDataTableV5 
                        responsive
                        hover 
                        entriesOptions={[5, 20, 25]} 
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable}
                    />
                </MDBContainer>
            </section>

            {standData ? (
                <Modal
                    size="lg"
                    show={showStand}
                    onHide={handleCloseStand}
                    className="admin-modal"
                >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {standData.nombre}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {standData.video
                        ? (
                            <Fragment>
                                <iframe 
                                    title="video"
                                    width="560" 
                                    height="315" 
                                    src={standData.video}
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen>
                                </iframe>
                                <p dangerouslySetInnerHTML={{__html: standData.descripcion}}  className="sm-12 md-6"/>
                            </Fragment>
                        ) 
                        : (
                            <p dangerouslySetInnerHTML={{__html: standData.descripcion}}  className="sm-12 md-6"/>
                        ) 
                    }
                    { !standData.imagen2 && !standData.imagen3
                        ? (
                            <img 
                                className="mx-2"
                                src={standData.imagen1} 
                                width="200px"
                            />
                        )
                        : null
                    }
                    { standData.imagen2 && standData.imagen3
                        ? (
                            <Fragment>
                                <img className="mx-2" src={standData.imagen1} width="200px" />
                                <img className="mx-2" src={standData.imagen2} width="200px" />
                                <img className="mx-2" src={standData.imagen3} width="200px" />
                            </Fragment>
                        ) : null
                    }
                    { !standData.imagen2 && standData.imagen3
                        ? (
                            <Fragment>
                                <img className="mx-2" src={standData.imagen1} width="200px" />
                                <img className="mx-2" src={standData.imagen3} width="200px" />
                            </Fragment>
                        ) : null
                    }
                    { standData.imagen2 && !standData.imagen3
                        ? (
                            <Fragment>
                                <img className="mx-2" src={standData.imagen1} width="200px" />
                                <img className="mx-2" src={standData.imagen2} width="200px" />
                            </Fragment>
                        ) : null
                    }
                    <br/>
                    <br/>
                    { !standData.imagen4 && standData.imagen5
                        ? (
                            <img className="mx-2" src={standData.imagen5} width="200px" />
                        ) : null
                    }
                    { standData.imagen4 && !standData.imagen5
                        ? (
                            <img className="mx-2" src={standData.imagen4} width="200px" />
                        ) : null
                    }
                    { standData.imagen4 && standData.imagen5
                        ? (
                            <Fragment>
                                <img className="mx-2" src={standData.imagen4} width="200px" />
                                <img className="mx-2" src={standData.imagen5} width="200px" />
                            </Fragment>
                        ) : null
                    }
                    <br/><br/>
                    {standData.catalogo 
                        ? (
                            <a 
                                href={standData.catalogo} 
                                target="_blank"
                                rel="noreferrer"
                            >
                                <MDBIcon size="2x" far icon="file-pdf" />
                            </a>
                        ) 
                        : null
                    }
                    {standData.whatsapp || standData.correo 
                        ? (
                            <Fragment>
                                {standData.whatsapp 
                                ? (
                                    <a 
                                        href={standData.whatsapp} 
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MDBIcon className="mx-2" size="2x" fab icon="whatsapp"/>
                                    </a>
                                ) 
                                : null}
                                {standData.correo 
                                ? (
                                    <a 
                                        href={standData.correo}
                                    >
                                        <MDBIcon size="2x" icon="far fa-envelope"/>
                                    </a>
                                ) 
                                : null}
                            </Fragment>
                        ) : null
                    }
                    {standData.facebook || standData.twitter
                        ? (
                            <Fragment>
                                {standData.facebook 
                                ? (
                                    <a 
                                        href={standData.facebook} 
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MDBIcon className="mx-2" size="2x" fab icon="facebook-f"/>
                                    </a>
                                ) 
                                : null}
                                {standData.twitter 
                                ? (
                                    <a 
                                        href={standData.twitter} 
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MDBIcon size="2x" fab icon="twitter"/>
                                    </a>
                                ) 
                                : null}
                            </Fragment>
                        ) 
                        : null
                    }
                    {standData.web
                        ? (
                            <a 
                                href={standData.web} 
                                rel="noreferrer" 
                                target="_blank">{standData.web}
                            </a>
                        ) 
                        : null
                    }
                    {standData.comentarios !== null 
                        ? (<p className="mt-3"><strong>Comentarios anteriores: </strong>{standData.comentarios}</p>)
                        : null
                    }
                    {standData.estado !== 3 ? (
                        <form >
                            <div className="form-group mt-4">
                                <textarea 
                                    className="form-control" 
                                    placeholder="Comentarios"
                                    name="comments"
                                    id="comments"
                                    onChange={handleChange}
                                >
                                </textarea>
                            </div>
                        </form>
                    ) : null}

                </Modal.Body>
                <Modal.Footer>

                {standData.estado !== 3 ? (
                    <Fragment>
                        {alert 
                            ? (<Alert className="mt-2" color="warning" message="No hay comentarios" />)
                            : null
                        }
                        {loadingComments ? (
                            <button
                                type="submit"
                                className="btnd btn-c"
                                disabled
                            >
                                Enviando comentarios
                                <div className="spinner-border spinner-border-sm ml-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </button>
                        ) : (<button 
                                className="btnd btn-c"
                                id="1"
                                enterpriseId={standData.id}
                                onClick={updateStandData}
                            >
                                Enviar comentarios 
                            </button>
                        )}
        
                        {loadingApprove ? (
                            <button
                                type="submit"
                                className="btnd btn-b"
                                disabled
                            >
                                Aprobando
                                <div className="spinner-border spinner-border-sm ml-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </button>
                        ) : (<button 
                                className="btnd btn-b"
                                id="2"
                                enterpriseId={standData.id}
                                onClick={updateStandData}
                            >
                                Aprobar
                            </button>
                        )}
                    </Fragment>
                ) : null}
                </Modal.Footer>
            </Modal>
            ) : null}
        </Fragment>
    );
}
 
export default StandsAdmin;