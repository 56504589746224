import React, { Fragment, useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import LoginContext from "../../../context/login/login.context";

import Navbar from "../navbar/navbar.component";
import Previews from "../../upload-images/upload-images.component";
import Alert from "../../alert/alert.component";
import axiosClient from "../../../config/axios";

import "./user-data-form.styles.css";

const UserDataForm = ({ history }) => {
  const [alert, setAlert] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);

  const loginContext = useContext(LoginContext);
  const { user, currentEvent } = loginContext;

  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productsListing, setProductsListing] = useState([]);
  const [products, setProducts] = useState({});

  const [image, setImage] = useState([]);

  const setParentImages = (image) => {
    setImage(image);
  };

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    whatsapp: "",
    job: "",
    socialReason: "",
    address: "",
    rfc: "",
    workers: "",
    activity: "",
  });

  const getProducts = async () => {
    const response = await axiosClient.get("api/enterprise/get-products", {
      params: { eventId: currentEvent, enterpriseType: user.id_tipo_empresa },
    });
    setProductsListing(response.data.data);
    setLoadingProducts(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      const { checked, id } = e.target;
      setProducts({
        ...products,
        [id]: checked,
      });
    }

    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleImage = () => {
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      whatsapp,
      job,
      socialReason,
      address,
      rfc,
      workers,
      activity,
    } = data;

    const keys = Object.keys(products);

    keys.forEach((key) => {
      if (products[key] === false) {
        delete products[key];
      }
    });

    if (Object.entries(products).length === 0) {
      setAlert(true);
      return;
    }

    if (
      whatsapp.trim() === "" ||
      job.trim() === "" ||
      socialReason.trim() === "" ||
      address.trim() === "" ||
      rfc.trim() === "" ||
      workers.trim() === "" ||
      activity.trim() === "" ||
      image[0] === undefined
    ) {
      setAlert(true);
      return;
    } else {
      try {
        let data = [];
        for (let key in products) {
          if (products[key]) data.push(Number(key));
        }

        let img = new Image();
        img.src = window.URL.createObjectURL(image[0]);

        console.log(img.width > 350 || img.height > 200)
        if (img.width > 350 || img.height > 200) {
          setAlertError("Imagen debe de ser menor a 350x200 pixeles.");
          return;
        }


        setLoading(true);
        setAlert(false);
        setAlertError(false);
        const dataNew = new FormData();
        dataNew.append("logo", image[0]);
        dataNew.append("whatsapp", whatsapp);
        dataNew.append("job", job);
        dataNew.append("socialReason", socialReason);
        dataNew.append("address", address);
        dataNew.append("rfc", rfc);
        dataNew.append("workers", workers);
        dataNew.append("activity", activity);
        dataNew.append("id", user.id_tipo_empresa);
        dataNew.append("enterpriseId", user.id_empresa);
        dataNew.append("userId", user.id);
        dataNew.append("products", data);

        const response = await axiosClient.post(
          "/api/users/user-data",
          dataNew
        );
        setLoading(false);

        if (response.status === 200 && user.id_tipo_empresa === 3) {
          history.push("/mi-stand-virtual");
        } else if (response.status === 200 && user.id_tipo_empresa === 2) {
          history.push("/registro-agenda");
        }
      } catch (error) {
        console.log(error.response);
        // setAlertError(error.response)
      }
    }
  };

  const isDataCompleted = async () => {
    try {
      let response = await axiosClient.get("/api/users/get-is-data-completed", {
        params: { id_empresa: user.id_empresa },
      });

      response = response.data.data[0];

      if (
        response.completada === 1 &&
        response.estado === 0 &&
        response.id_tipo_empresa === 3
      ) {
        history.push("/mi-stand-virtual");
      } else if (
        response.completada === 1 &&
        response.estado !== 0 &&
        response.id_tipo_empresa === 3
      ) {
        history.push("/citas");
      } else if (response.completada === 1 && response.id_tipo_empresa === 2) {
        history.push("/registro-agenda");
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    isDataCompleted();
    if (currentEvent) getProducts();
    
  }, [currentEvent]);

  return (
    <Fragment>
      <Helmet title="Formulario de inicio" />
      <Navbar />
      <section className="mt-5">
        <MDBContainer>
          {alertSuccess ? (
            <Alert color="success" message={alertSuccess} />
          ) : null}
          {alert ? (
            <Alert color="danger" message="Todos los campos son obligatorios" />
          ) : null}
          {alertError ? <Alert color="danger" message={alertError} /> : null}
          <form onSubmit={handleSubmit}>
            <MDBRow>
              <MDBCol sm="12" md="6" className="mb-3">
                <Previews
                    onChange={handleImage}
                  setParentImages={setParentImages}
                  maxFiles="1"
                  stand={false}
                />
              </MDBCol>
              <MDBCol sm="12" md="6">
                <h6>
                  <strong>Datos de usuario:</strong>
                </h6>
                <div className="form-group">
                  <input
                    name="whatsapp"
                    type="tel"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    className="form-control"
                    placeholder="Whatsapp"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    name="job"
                    type="text"
                    className="form-control"
                    placeholder="Puesto"
                    maxlength="30"
                    onChange={handleChange}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="12" md="6">
                <h6>
                  <strong>Datos de empresa:</strong>
                </h6>
                <MDBRow>
                  <MDBCol sm="12" md="6">
                    <div className="form-group">
                      <input
                        name="socialReason"
                        type="text"
                        className="form-control"
                        placeholder="Razón social"
                        maxLength="30"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="address"
                        type="text"
                        className="form-control"
                        placeholder="Domicilio"
                        maxLength="50"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="rfc"
                        type="text"
                        className="form-control"
                        placeholder="RFC"
                        minLength="12"
                        maxlength="13"
                        onChange={handleChange}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" md="6">
                    <div className="form-group">
                      <input
                        name="workers"
                        type="number"
                        className="form-control"
                        placeholder="Número de trabajadores"
                        maxLength="6"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="activity"
                        type="text"
                        className="form-control"
                        placeholder="Actividad principal"
                        maxLength="30"
                        onChange={handleChange}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol sm="12" md="6">
                {loadingProducts ? (
                  <div className="text-center mb-2">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <p>
                      <strong>
                        Selecciona los productos/servicios
                        {user.id_tipo_empresa === 3
                          ? " ofrecidos:"
                          : " requeridos:"}
                      </strong>
                    </p>
                    <div className="products">
                      <MDBRow>
                        {productsListing.map((item) => (
                          <MDBCol sm="4">
                            <label htmlFor={item.id}>
                              <input
                                key={item.id}
                                id={item.id}
                                type="checkbox"
                                onChange={handleChange}
                              />
                              {item.nombre}
                            </label>
                          </MDBCol>
                        ))}
                      </MDBRow>
                    </div>
                  </Fragment>
                )}
              </MDBCol>
            </MDBRow>
            {loading ? (
              <button type="submit" className="btnd btn-b btn-w" disabled>
                Guardando
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                className="btnd
                                    btn-b 
                                    btn-w 
                                    my-4
                                    "
              >
                Guardar
              </button>
            )}
          </form>
        </MDBContainer>
      </section>
    </Fragment>
  );
};

export default UserDataForm;
