import React, { useState, useEffect, Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDataTableV5,
  MDBBtnGroup,
  MDBAlert,
} from "mdbreact";

import AdminNav from "../navbar/navbar.component";
import LoginContext from "../../../context/login/login.context";
import axiosClient from "../../../config/axios";
import Alert from "../../alert/alert.component";

//agregar modal con la opcion de reagendar o seleccionar la hora que mas convenga al administrador
//agregar propiedad de display none a los botones para que no realice nuevamente la accion si ya esta aceptada o no aceptada

const Request = () => {
  const loginContext = useContext(LoginContext);
  const { currentEvent } = loginContext;
  const [alertSuccess, setAlertSuccess] = useState(false);

  //agregue esto Alan
  const [enterprises, setEnterprises] = useState({});
  const [loadingEnterprises, setLoadingEnterprises] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [buyer, setBuyer] = useState([]);

  const handleCloseReschedule = () => setShowReschedule(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const handleShowReschedule = async (e) => {
    try {
      setTimeout(() => {
        const rescheduleSystemButton = document.getElementById(
          "rescheduleSystem"
        );
        let attribute = document.createAttribute("idBuyer");
        attribute.value = e.target.attributes.idEnterpriseBuyer.value;
        if (rescheduleSystemButton !== null) {
          rescheduleSystemButton.setAttributeNode(attribute);
          attribute = document.createAttribute("idProvider");
          attribute.value = e.target.attributes.idProvider.value;
          rescheduleSystemButton.setAttributeNode(attribute);
          attribute = document.createAttribute("idRequest");
          attribute.value = e.target.attributes.idSolicitud.value;
          rescheduleSystemButton.setAttributeNode(attribute);
        }
        return;
      }, 1000);
      var dataSchedule = [];
      setAlertError(false);
      setAlertSuccess(false);
      var idEnterpriseBuyer = e.target.attributes.idEnterpriseBuyer.value;
      var idProvider = e.target.attributes.idProvider.value;
      var idSolicitud = e.target.attributes.idSolicitud.value;
      const response = await axiosClient.get(
        "/api/appointment/query-provider",
        {
          params: {
            idEvent: currentEvent,
            idEnterpriseBuyer: idEnterpriseBuyer,
            idProvider: idProvider,
          },
        }
      );
      for (let i = 0; i < response.data.data.arr.length; i++) {
        const element = response.data.data.arr[i];
        element.actions = (
          <button
            className="btnd btn-b"
            idSolicitud={idSolicitud}
            idAgenda={response.data.data.arr[i].id}
            idProveedor={idProvider}
            onClick={handleRescheduleManual}
          >
            Reagendar
          </button>
        );
        dataSchedule.push(element);
      }

      setDatatableReschedule({
        ...datatableReschedule,
        rows: dataSchedule,
      });
      setShowReschedule(true);
    } catch (error) {
      console.log(error.response);
      setAlertError(error.response.data.message);
      return;
    }
  };

  const handleRescheduleManual = async (e) => {
    try {
      setAlertError(false);
      setAlertSuccess(false);
      var idRequest = e.target.attributes.idSolicitud.value;
      var idAgenda = e.target.attributes.idAgenda.value;
      var idProveedor = e.target.attributes.idProveedor.value;

      const response = await axiosClient.post(
        "/api/enterprise/reschedule-manual",
        {
          params: {
            idSolicitud: idRequest,
            idAgenda: idAgenda,
            idProveedor: idProveedor,
          },
        }
      );
      handleChange();
      handleCloseReschedule();
      setAlertSuccess(response.data.message);
    } catch (error) {
      console.log(error.response);
    }
  };

  const [data, setData] = useState([]);
  const handleAccept = async (e) => {
    try {
      setAlertError(false);
      setAlertSuccess(false);
      var idAgenda = e.target.attributes.idAgenda.value;
      const response = await axiosClient.patch(
        "/api/enterprise/request-accept",
        { params: { idRequest: e.target.id, idAgenda: idAgenda } }
      );
      setAlertSuccess(response.data.message);
      setDatatable({
        ...datatable,
        rows: [],
      });
      handleChange();
    } catch (error) {
      console.log(error.response);
      setAlertSuccess(error.response.data.message);
    }
  };

  const handleNotAccept = async (e) => {
    try {
      setAlertError(false);
      setAlertSuccess(false);
      var idAgenda = e.target.attributes.idAgenda.value;
      const response = await axiosClient.patch(
        "/api/enterprise/request-not-accept",
        { params: { idRequest: e.target.id, idAgenda: idAgenda } }
      );
      setAlertSuccess(response.data.message);
      setDatatable({
        ...datatable,
        rows: [],
      });
      handleChange();
    } catch (error) {
      setAlertSuccess(error.response.data.message);
      console.log(error.response);
    }
  };

  const handleReschedule = (e) => {
    var idEnterpriseBuyer = e.target.attributes.idEnterpriseBuyer.value;
    var idAgenda = e.target.attributes.idAgenda.value;
    setBuyer(idEnterpriseBuyer, idAgenda);
    setShow(true);
  };

  const handleChange = async () => {
    try {
      setAlertError(false);
      setAlertSuccess(false);
      var dataNew = [];
      if (document.getElementById("enterprise").value !== "") {
        let response = await axiosClient.get("/api/enterprise/get-requests", {
          params: {
            idEnterprise: document.getElementById("enterprise").value,
            eventId: currentEvent,
          },
        });
        response = response.data.data;
        if(response.length === 0){
          setDatatable({
            columns: [
              {
                label: "Id",
                field: "id",
              },
              {
                label: "Compradora",
                field: "buyer",
              },
              {
                label: "Fecha",
                field: "date",
              },
              {
                label: "Hora",
                field: "hour",
              },
              {
                label: "Compatibilidad",
                field: "compatibility",
              },
              {
                label: "Estatus",
                field: "status",
              },
              {
                label: "Acciones",
                field: "actions",
              },
            ],
            rows: [],
          });
        }
        console.log(response.length)
        for (let i = 0; i < response.result.length; i++) {
          const element = response.result[i];
          for (let j = 0; j < response.dataNew.length; j++) {
            if (response.result[i].id === response.dataNew[j].idRequest) {
              element.compatibility =
                response.dataNew[j].numProducts +
                " Productos Compatibles de " +
                response.dataNew[j].totalProducts;
              break;
            } else {
              if (j === response.dataNew.length - 1) {
                element.compatibility = "No hay Productos Compatibles";
              }
            }
          }

          if (response.result[i].status === "En Proceso") {
            element.status = (
              <MDBAlert color="primary">{response.result[i].status}</MDBAlert>
            );
            element.actions = (
              <MDBBtnGroup size="sm">
                <button
                  className="btnd btn-b"
                  id={response.result[i].id}
                  idAgenda={response.result[i].idAgenda}
                  onClick={handleAccept}
                >
                  Aceptar
                </button>
                <button
                  className="btnd btn-c"
                  id={response.result[i].id}
                  idAgenda={response.result[i].idAgenda}
                  onClick={handleNotAccept}
                >
                  Rechazar
                </button>
                <button
                  className="btnd btn-i"
                  id={response.result[i].id}
                  idEnterpriseBuyer={response.result[i].idEnterpriseBuyer}
                  idAgenda={response.result[i].idAgenda}
                  idProvider={response.result[i].id_provider}
                  idSolicitud={response.result[i].id}
                  onClick={handleShowReschedule}
                >
                  Reagendar
                </button>
              </MDBBtnGroup>
            );
          } else if (response.result[i].status === "Aceptada") {
            element.status = (
              <MDBAlert color="success">{response.result[i].status}</MDBAlert>
            );
          } else {
            element.status = (
              <MDBAlert color="danger">{response.result[i].status}</MDBAlert>
            );
          }
          dataNew.push(element);
        }
        setData([...data]);
        setDatatable({
          columns: [
            {
              label: "Id",
              field: "id",
            },
            {
              label: "Compradora",
              field: "buyer",
            },
            {
              label: "Fecha",
              field: "date",
            },
            {
              label: "Hora",
              field: "hour",
            },
            {
              label: "Compatibilidad",
              field: "compatibility",
            },
            {
              label: "Estatus",
              field: "status",
            },
            {
              label: "Acciones",
              field: "actions",
            },
          ],
          rows: dataNew,
        });
      } else {
        /* setDatatable({        
                        ...datatable,
                        rows: []
                    }) */
        var dataNew = [];
        let response = await axiosClient.get(
          "/api/appointment/get-all-requests",
          { params: { eventId: currentEvent } }
        );
        response = response.data.data;

        for (let i = 0; i < response.result.length; i++) {
          const element = response.result[i];
          for (let j = 0; j < response.dataNew.length; j++) {
            if (response.result[i].id === response.dataNew[j].idRequest) {
              element.compatibility =
                response.dataNew[j].numProducts +
                " Productos Compatibles de " +
                response.dataNew[j].totalProducts;
              break;
            } else {
              if (j === response.dataNew.length - 1) {
                element.compatibility = "No hay Productos Compatibles";
              }
            }
          }

          for (let l = 0; l < response.dataProviders.length; l++) {
            if (
              response.result[i].id_proveedor === response.dataProviders[l].id
            ) {
              element.provider = response.dataProviders[l].nombre;
            } else {
              element.provider = response.result[i].provider;
            }
          }

          //element.provider = response.dataProviders[i]
          element.status = (
            <MDBAlert color="primary">{response.result[i].status}</MDBAlert>
          );
          element.actions = (
            <MDBBtnGroup size="sm">
              <button
                className="btnd btn-b"
                id={response.result[i].id}
                idAgenda={response.result[i].idAgenda}
                onClick={handleAccept}
              >
                Aceptar
              </button>
              <button
                className="btnd btn-c"
                id={response.result[i].id}
                idAgenda={response.result[i].idAgenda}
                onClick={handleNotAccept}
              >
                Rechazar
              </button>
              <button
                className="btnd btn-i"
                id={response.result[i].id}
                idEnterpriseBuyer={response.result[i].idEnterpriseBuyer}
                idAgenda={response.result[i].idAgenda}
                idProvider={response.result[i].id_proveedor}
                idSolicitud={response.result[i].id}
                onClick={handleShowReschedule}
              >
                Reagendar
              </button>
            </MDBBtnGroup>
          );
          dataNew.push(element);
        }
        setData([...data]);
        setDatatable({
          columns: [
            {
              label: "Id",
              field: "id",
            },
            {
              label: "Compradora",
              field: "buyer",
            },
            {
              label: "Proveedora",
              field: "provider",
            },
            {
              label: "Fecha",
              field: "date",
            },
            {
              label: "Hora",
              field: "hour",
            },
            {
              label: "Compatibilidad",
              field: "compatibility",
            },
            {
              label: "Estatus",
              field: "status",
            },
            {
              label: "Acciones",
              field: "actions",
            },
          ],
          rows: dataNew,
        });
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSystemReschedule = async (e) => {
    try {
      setAlertError(false);
      setAlertSuccess(false);
      const idEnterpriseProvider = e.target.attributes.idprovider.value;
      var idEnterpriseBuyer = e.target.attributes.idbuyer.value;
      var idRequest = e.target.attributes.idrequest.value;

      const response = await axiosClient.post(
        "/api/enterprise/reschedule-system",
        {
          params: {
            idEnterpriseProvider: idEnterpriseProvider,
            idEnterpriseBuyer: idEnterpriseBuyer,
            idRequest: idRequest,
            eventId: currentEvent,
          },
        }
      );

      //console.log(response.data.data)
      setAlertSuccess(
        "Se reagendo la cita para el " +
          response.data.data.date +
          " a las " +
          response.data.data.horario
      );
      handleCloseReschedule();
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const [request, setRequest] = useState({});

  const [enterpriseType, setEnterpriseType] = useState({});
  const handleEnterpriseType = async (e) => {
    const response = await axiosClient.get(
      "/api/enterprise/get-enterprise-buyers-prividers",
      { params: { enterpriseType: 3, eventId: currentEvent } }
    );
    setEnterprises(response.data.data);
    setLoadingEnterprises(false);
    handleChange();
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Id",
        field: "id",
      },
      {
        label: "Compradora",
        field: "buyer",
      },
      {
        label: "Fecha",
        field: "date",
      },
      {
        label: "Hora",
        field: "hour",
      },
      {
        label: "Compatibilidad",
        field: "compatibility",
      },
      {
        label: "Estatus",
        field: "status",
      },
      {
        label: "Acciones",
        field: "actions",
      },
    ],
  });

  const [datatableReschedule, setDatatableReschedule] = useState({
    columns: [
      {
        label: "Fecha",
        field: "date",
      },
      {
        label: "Hora",
        field: "hour",
      },
      {
        label: "Acciones",
        field: "actions",
      },
    ],
  });

  useEffect(() => {
    handleEnterpriseType();
  }, [currentEvent]);

  return (
    <Fragment>
      <Helmet title="Solicitudes" />
      <AdminNav userType={1} />
      <section>
        <MDBContainer className="mt-5">
          <h4>
            <strong>Solicitudes</strong>
          </h4>
          <MDBRow className="my-4">
            <MDBCol sm="12" md="6" className="form-group">
              <label htmlFor="enterprise">
                <strong>Empresas</strong>
              </label>
              <select
                className="form-control"
                id="enterprise"
                name="enterprise"
              >
                <option onClick={handleChange} value="">
                  Seleccionar
                </option>
                {loadingEnterprises
                  ? null
                  : enterprises.map((enterprise) => (
                      <option
                        key={enterprise.id}
                        value={enterprise.id}
                        onClick={handleChange}
                      >
                        {enterprise.nombre}
                      </option>
                    ))}
              </select>
            </MDBCol>
            <br></br>
          </MDBRow>
          {alertSuccess ? (
            <Alert color="success" message={alertSuccess} />
          ) : null}
          {alertError ? <Alert color="danger" message={alertError} /> : null}
        </MDBContainer>
      </section>
      <section>
        <MDBContainer>
          <MDBDataTableV5
            responsive
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={datatable}
          />
        </MDBContainer>
      </section>

      <Modal show={showReschedule} onHide={handleCloseReschedule}>
        <Modal.Header closeButton>
          <Modal.Title>Horarios Disponibles para Reagendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <MDBContainer>
              <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={datatableReschedule}
              />
            </MDBContainer>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="rescheduleSystem"
            className="btnd btn-b"
            onClick={handleSystemReschedule}
          >
            Reagendar por Sistema
          </button>
          <button className="btnd btn-c" onClick={handleCloseReschedule}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Request;