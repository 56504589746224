import jsPdf from 'jspdf'
import 'jspdf-autotable'

export const cleanImage = (image) => {
    if (image !== null && image !== undefined) {
        if(process.env.REACT_APP_BACKEND_URL === "http://localhost:4444"){
            let domain = process.env.REACT_APP_BACKEND_URL
            let path = domain + "/" + image.slice(66)
            return path
        }
        else{
            let domain = process.env.REACT_APP_BACKEND_URL.slice(11)
            let protocol = process.env.REACT_APP_BACKEND_URL.slice(0, 7)
            let path = protocol + domain + image.slice(25)
            return path
        }
    }
}

export const generatePdf = (schedules, empresa) => {
    const doc = new jsPdf()
    const rows = []
    const columns = schedules.columns.map(column => column.label)

    if (columns.includes('Link Zoom')) columns.pop()

    schedules.rows.forEach(schedule => {
        const scheduleData = [
            schedule.id,
            schedule.nombre,
            schedule.fecha,
            schedule.horario,
            schedule.status,
        ]
        rows.push(scheduleData) 
    })

    doc.text(`Agenda de ${empresa}`, 15, 15)    
    doc.autoTable(columns, rows, { 
        startY: 30,
        headStyles: {
            fillColor: '#353535'}
    })
    doc.save(`Agenda_${empresa}.pdf`)
}   
