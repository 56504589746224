import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import FormContainer from "../form-container/form-container.component";

import Alert from "../../alert/alert.component";
import axiosClient from "../../../config/axios";

const RecoverPassword = () => {
  const [email, setEmail] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertSucces, setAlertSuccess] = useState(false);

  const handleChange = (e) => setEmail({ [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email.email.trim() === "") {
      setAlert("Ingrese un correo.");
      setLoading(false);
      return;
    }

    try {
        const response = await axiosClient.post("api/users/recover-password", email);
        setAlertSuccess(response.data.message)
        setAlert(false);
        setEmail({email: ''})
    } catch (error) {
        setAlert(error.response.data.message)
        return
    } finally {
        setLoading(false);
    }

  };

  return (
    <Fragment>
      <Helmet title="Recuperar contraseña" />
      <FormContainer>
        <form onSubmit={handleSubmit}>
          {alert && alert ? <Alert color="danger" message={alert} /> : null}
          {alertSucces ? <Alert color="success" message={alertSucces} /> : null}
          <div className="form-group">
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Correo"
              value={email.email}
              onChange={handleChange}
            />
          </div>
          {loading ? (
            <button className="btnd btn-b" disabled>
              Espere...
              <div
                className="spinner-border spinner-border-sm ml-2"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          ) : (
            <input
              type="submit"
              value="Recuperar contraseña"
              className="btnd btn-b"
            />
          )}
        </form>
      </FormContainer>
    </Fragment>
  );
};

export default RecoverPassword;
