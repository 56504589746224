import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Helmet } from 'react-helmet'
import {
    MDBContainer, 
    MDBRow,
    MDBCol,
    MDBDataTableV5,
} from 'mdbreact'
import { Modal } from 'react-bootstrap'

import AdminNav from '../navbar/navbar.component'
import Alert from '../../alert/alert.component'
import LoginContext from '../../../context/login/login.context'
import axiosClient from '../../../config/axios'

const Products = () => {
    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const [alert, setAlert] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)

    const [loadingProducts, setLoadingProducts] = useState(false)
    const [loadingEnterprises, setLoadingEnterprises] = useState(true)

    const [products, setProducts] = useState({ enterpriseId: '', products: '', enterpriseType: '' })

    const [enterprises, setEnterprises] = useState([])
    
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false) 
    const handleShow = () => {
        setShow(true)
        setAlertSuccess(false)
    }

    const getEnterprises = async (id, currentEvent) => {
        const response = await axiosClient.get('/api/enterprise/get-enterprise-buyers-prividers',
                                         { params: { enterpriseType: id, eventId: currentEvent } })
        setEnterprises(response.data.data)
        setProducts({ ...products, enterpriseType: id})
        setLoadingEnterprises(false)
    }
    
    const handleEnterpriseType = async e => {
        try{
            getEnterprises(e.target.id, currentEvent)
        } catch (error) {
            console.log(error.response)
        }
    }

    const handleChange = e => {
        setProducts({
            ...products,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoadingProducts(true)
        const response = await axiosClient.post('/api/enterprise/add-products', products)
        setAlertSuccess(response.data.message)
        setLoadingProducts(false)
        setShow(false)
    }

    useEffect(()  => {
        getEnterprises(2, currentEvent)
        // eslint-disable-next-line
    }, [])
    
    return (
        <Fragment>
            <Helmet
                title="Productos"
            />
            <AdminNav userType={1} />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Productos o Servicios</strong></h4>
                    <button className="btnd btn-b mb-3" onClick={handleShow}>
                        Agregar
                    </button>
                    { alertSuccess ? (
                        <Alert color="success" message={alertSuccess} />
                    ) : null }
                </MDBContainer>
            </section>
            <Modal show={show} onHide={handleClose}>
                <form 
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Agregar Productos
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { alert ? (
                            <Alert color="danger" message="Todos los datos son obligatorios" />
                        ) : null }

                        { loadingProducts ? (
                            <div className="text-center mb-2">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null }

                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    id="2" 
                                    name="enterpriseType"
                                    onChange={handleEnterpriseType}
                                />
                                <label className="form-check-label">Compradoras</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    id="3" 
                                    name="enterpriseType"
                                    onChange={handleEnterpriseType}
                                />
                                <label className="form-check-label">Proveedoras</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="enterprise"><strong>Empresas</strong></label>
                            <select 
                                className="form-control"
                                id="enterpriseId"
                                name="enterpriseId"
                                onChange={handleChange}
                            >
                                <option value="">-- Seleccionar --</option>
                                { loadingEnterprises ? null : (
                                    enterprises.map(enterprise => (
                                        <option
                                            value={enterprise.id}
                                            key={enterprise.id}
                                        >
                                            {enterprise.nombre}
                                        </option>
                                    ))
                                ) }
                            </select>
                        </div> 
                        <div className="form-group">
                            <textarea 
                                name="products"
                                className="form-control" 
                                id="products"
                                rows="5" 
                                cols="150" 
                                placeholder="producto1, producto2, etc..."
                                onChange={handleChange}
                            ></textarea>
                        </div> 
                    </Modal.Body>
                    <Modal.Footer>
                        <input 
                            className="btnd btn-b"
                            type="submit"
                            value="Guardar"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    );
}
 
export default Products;