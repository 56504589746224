import React, {useContext, useEffect, Fragment, useState} from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { MDBContainer } from 'mdbreact'

import LoginContext from '../../../context/login/login.context'

import { cleanImage } from '../../../config/helper'
import "./navbar.styles.css"


const MainNav = () => {
    const loginContext = useContext(LoginContext)
    const { logOut, getUser, user, switchEvent, logo, currentEvent } = loginContext

    const [image, setImage] = useState()
    const [loadingImage, setLoadingImage] = useState(false)

    const getImage = () => {
        setLoadingImage(true)
        let logoImagen = cleanImage(logo)
        setImage(logoImagen)
        setLoadingImage(false)
    }

    const [url, setUrl] = useState()

    const getUrl = () => {
        let path = window.location.href
        let pagina = path.slice(-16)
        setUrl(pagina)
    }

    const handleSwitchEvent = (e) => {
        switchEvent(e.target.id)
    }

    useEffect(() => {
        getUser()
        getUrl()
        if (currentEvent === null) switchEvent(user.eventos[0].id_evento)
    }, [])

    useEffect(() => {
        getImage()
    }, [logo])
    
    return (
        <Navbar className="nav-d" variant="dark" expand="md">
        {!user ? null : (
            <MDBContainer>
                <Navbar.Brand>
                    <Link to="/">
                        { loadingImage ? (
                            <div className="text-center mb-2">
                                <div className="spinner-border text-danger" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <img src={image} width="100" alt=""/>
                        ) }
                    </Link>
                </Navbar.Brand>
                    <Navbar.Toggle abg="dark"ria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                        {url === "formulario-datos" || url === "mi-stand-virtual" || url === "/registro-agenda" ? 
                        <Nav className="ml-auto">
                            <NavDropdown 
                                title={ user.id_tipo_empresa === 1 ? "Visitante" : `${user.nombre_empresa}` } 
                                id="basic-nav-dropdown">
                                <Link onClick={() => logOut() } className="nav-element">Salir</Link> 
                            </NavDropdown>
                        </Nav>
                        :
                        
                            <Nav className="ml-auto"> 
                                <Link className="nav-element" to="/directorio">Directorio</Link>
                                <Link className="nav-element" to="/stands">Stands</Link>
                                { user.id_tipo_empresa === 3 || user.id_tipo_empresa === 2 ? (
                                    <Link className="nav-element" to="/citas">Encuentro de negocios</Link>
                                ) : null }
                                <NavDropdown 
                                    title={ user.id_tipo_empresa === 1 ? "Visitante" : `${user.nombre_empresa}` } 
                                    id="basic-nav-dropdown">
                                    { user.id_tipo_empresa !== 1 ? (
                                        <Fragment>
                                            <Link to="/mis-datos" className="drop-item">Mis datos</Link>
                                            <Link to="/mi-agenda" className="drop-item">Agenda</Link>
                                            {/* { user.id_tipo_empresa === 2  ? (
                                                <Link to="/registro-agenda" className="drop-item">Generar agenda</Link>
                                            ) : null} */}
                                            { user.id_tipo_empresa === 3  ? (
                                                <Link to="/mi-stand-virtual?stand" className="drop-item">Mi stand</Link>
                                            ) : null}
                                            { user.eventos.length > 1 ? (
                                                <NavDropdown
                                                    title="Mis Eventos"  
                                                    id="basic-nav-dropdown"
                                                    drop="left"
                                                >
                                                    <Fragment>
                                                        { user.eventos.map(item => (
                                                            <Link 
                                                                id={item.id_evento}
                                                                onClick={handleSwitchEvent}
                                                                className={item.id_evento === currentEvent ? "drop-item active" : "drop-item" }
                                                            >
                                                                {item.nombre}
                                                            </Link>
                                                        ))}
                                                    </Fragment>
                                                </NavDropdown>
                                            ) : null }
                                            <NavDropdown.Divider />
                                        </Fragment>
                                    ) : null }
                                    <Link onClick={() => logOut() } className="drop-item">Salir</Link>
                                </NavDropdown>
                            </Nav>
                    
                        }
                </Navbar.Collapse>
            </MDBContainer>
        )}
        </Navbar>
    );
}
 
export default MainNav;