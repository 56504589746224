import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBDataTableV5,
    MDBAlert
} from 'mdbreact'

import Navbar from '../navbar/navbar.component'
import LoginContext from '../../../context/login/login.context'
import axiosClient from '../../../config/axios'
import { generatePdf } from '../../../config/helper'


const MySchedule = ({ history }) => {
    const loginContext = useContext(LoginContext)
    const { user, currentEvent } = loginContext

    const [scheduleRegister, setScheduleRegister] = useState({})
    const [loadingSchedule, setLoadingSchedule] = useState(true)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const [linkZoom, setLinkZoom] = useState()
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: 'Id',
                field: 'id'
            },
            {
                label: 'Proveedor',
                field: 'nombre',
            },
            {
                label: 'Fecha',
                field: 'fecha',
            },
            {
                label: 'Hora',
                field: 'horario',
            },
            {
                label: 'Status',
                field: 'status',
            },
            {
                label: 'Stand virtual',
                field: 'slug',
            },
        ], 
    })

    const [datatableSchedule, setDatatableSchedule] = useState({
        columns: [
          {
            label: "Fecha",
            field: "date",
          },
          {
            label: "Hora",
            field: "hour",
          },
        ],
      });

    const handleShow = async () => {
        try{
            var dataSchedule = [];
            let response = await axiosClient.get('/api/users/get-schedule-register', 
                                            { params: { 
                                                idUser: user.id, 
                                                idEvent: currentEvent
                                                } 
                                            } )
            /* response = response.data.data
            for (let i = 0; i < response.length; i++) {
                dataSchedule.push(response[i]);
            } */

            setDatatableSchedule({
                ...datatableSchedule,
                rows: response.data.data,
            });
            setLoadingSchedule(false)
            setShow(true)
        } catch (error){
            console.log(error.response)
        }
    }
    
    const getSchedule = async () =>{
        if(user.id_tipo_empresa === 2){
            try {
                const response = await axiosClient.get('/api/users/get-schedule', 
                { params: { buyerFlag: true, userId: user.id, eventId: currentEvent } })
                setLinkZoom(response.data.resultLinkZoom[0].link_zoom)
                var data = response.data.data
                for (let i = 0; i < data.length; i++) {
                    data[i].slug = 
                    <Link 
                        className="blue-text"
                        to={`/stand/${data[i].slug}`}>
                            {data[i].slug}
                    </Link>
                    if(data[i].status === "En Proceso"){
                        data[i].status = <MDBAlert color="primary" >En Proceso</MDBAlert>
                    }
                    else if(data[i].status === "Aceptada"){
                        data[i].status = <MDBAlert color="success" >Aceptada</MDBAlert>
                    }
                }
                setDatatable({
                    ...datatable,
                    rows: response.data.data
                })
            } catch (error) {
                console.log(error.response)
            }
        }
        else{
            try {
                const response = await axiosClient.get('/api/users/get-schedule', 
                    { params: { providerFlag: true, enterpriseId: user.id_empresa, eventId: currentEvent } })
                var data = response.data.data
                for (let i = 0; i < data.length; i++) {
                    data[i].link_zoom = <a 
                        href={data[i].link_zoom} 
                        target="_blank" 
                        rel="noreferrer"
                        className="blue-text"
                        >
                            Click Aqui
                        </a>
                }
                setDatatable({
                    columns: [
                        {
                            label: 'Id',
                            field: 'id'
                        },
                        {
                            label: 'Comprador',
                            field: 'nombre',
                        },
                        {
                            label: 'Fecha',
                            field: 'fecha',
                        },
                        {
                            label: 'Hora',
                            field: 'horario',
                        },
                        {
                            label: 'Status',
                            field: 'status',
                        },
                        {
                            label: 'Link Zoom',
                            field: 'link_zoom',
                        },
                    ], 
                    rows: data
                })
            } catch (error) {
                console.log(error.response)
            }
        }
    }

    useEffect(() => {
        if(user){
            if (user.id_tipo_empresa === 1)
                history.push('/stands')
            getSchedule()
        }
    }, [])

    return (
        <Fragment>
            <Helmet
                title="Mi agenda"
            />
            <Navbar />
            <section className="mt-5">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol sm="12" md="12">
                            <div className="form-group">
                                <center><h2><strong>Mi agenda</strong></h2></center>
                            </div> 
                        </MDBCol>
                    </MDBRow>
                    <button 
                        className="btnd btn-b"
                        onClick={() => generatePdf(datatable, user.nombre_empresa)}
                    > 
                        Exportar agenda 
                    </button>
                    { user.id_tipo_empresa === 2 ? (
                    <button 
                        className="btnd btn-c ml-4"
                        onClick={handleShow}
                    > 
                        Mis Horarios
                    </button>
                    ) : null }
                </MDBContainer>
            </section>
            <section className="mt-5">
                <MDBContainer>
                    { user.id_tipo_empresa === 2 ? (
                        <MDBRow className="mb-4">
                            <h5>
                                Enlace a las Reuniones:  
                                <small>
                                    <a 
                                        href={linkZoom}
                                        target="_blank"
                                        className="blue-text"
                                        rel="noreferrer"
                                    >
                                        Click Aquí
                                    </a>
                                </small>
                            </h5>
                        </MDBRow>
                    ) : null }
                    <MDBDataTableV5 
                        responsive
                        hover 
                        entriesOptions={[5, 20, 25]} 
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable} 
                    />
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Mi Horario                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="modal" name="modal">                  
                    <section>
                        <MDBContainer>
                            <MDBDataTableV5
                                hover
                                entriesOptions={[5, 20, 25]}
                                entries={5}
                                pagesAmount={4}
                                data={datatableSchedule}
                            />
                        </MDBContainer>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnd btn-c" onClick={handleClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );    
}

export default MySchedule;