import React, { useEffect, useState, useMemo, Fragment } from "react";
import { MDBIcon } from "mdbreact";
import { useDropzone } from "react-dropzone";

import "./upload-images.styles.css";

const UploadImage = (props) => {
  const { maxFiles, setParentImages, stand } = props;
  const activeStyle = { borderColor: "#353535", color: "#353535" };
  const [images, setImages] = useState();
  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
  } = useDropzone({
    accept: ".png, .jpg, .jpeg",
    onDrop: (acceptedFiles) => {
      setImages(
        acceptedFiles.map((image) =>
          Object.assign(image, {
            preview: URL.createObjectURL(image),
          })
        )
      );
    },
    maxFiles: maxFiles,
    maxSize: 100000,
  });

  useEffect(() => {
    if (images !== undefined) {
      images.forEach((image) => URL.revokeObjectURL(image.preview));
      setParentImages(images);
    }
    // showErrors(fileRejections)
  }, [images]);

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
    }),
    [isDragActive, activeStyle]
  );

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  if (stand) {
    return (
      <Fragment>
        <div {...getRootProps({ className: "dropzone text-center", style })}>
          <input {...getInputProps()} />
          {images ? (
            images.map((image) => (
              <Fragment>
                <img src={image.preview} className="my-3" alt="logo" />
              </Fragment>
            ))
          ) : (
            <p>
              <MDBIcon size="4x" icon="fas fa-cloud-upload-alt" />
              <br />
              Arrastra las imágenes de tu empresa.
            </p>
          )}

          {fileRejections.length > 0 ? (
            <p>
              <br />
              Arrastra las imágenes de tu empresa.
            </p>
          ) : null}
        </div>
        <ul className="mt-3">
          <li>Mínimo una imagen, máximo cinco.</li>
          <li>Tamaño maximo: 100 KB.</li>
          <li>Dimensiones maximo: 350px x 200px.</li>
          <li>Formatos: JPG / PNG / JPE.G</li>
        </ul>
        <ul className="error-images">{fileRejectionItems}</ul>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div {...getRootProps({ className: "dropzone text-center", style })}>
          <input {...getInputProps()} />
          {images ? (
            images.map((image) => (
              <Fragment>
                <img src={image.preview} className="my-3" alt="logo" />
                <p>
                  Arrastra o <strong>selecciona</strong> logo de tu empresa.
                </p>
              </Fragment>
            ))
          ) : (
            <p>
              <MDBIcon size="4x" icon="fas fa-cloud-upload-alt" />
              <br />
              Arrastra logo de tu empresa.
            </p>
          )}

          {fileRejections.length > 0 ? (
            <p>
              <MDBIcon size="4x" icon="fas fa-cloud-upload-alt" />
              <br />
              Arrastra logo de tu empresa.
            </p>
          ) : null}
        </div>
        <ul className="mt-3">
          <li>Tamaño maximo: 100 KB</li>
          <li>Dimensiones maximo: 350px x 200px</li>
          <li>Formatos: JPG / PNG / JPEG</li>
        </ul>
        <ul className="error-images">{fileRejectionItems}</ul>
      </Fragment>
    );
  }
};

export default UploadImage;
