import React from 'react'
import { MDBAlert } from 'mdbreact'

const VerboseAlert = (props) => {
    const { title, content, footer, color } = props
    return (
        <MDBAlert color={color} className="my-3">
            <h4 className="alert-heading">{ title }</h4>
            <p> { content } </p>
            <hr/>
            { footer }
        </MDBAlert>
    );
}
 
export default VerboseAlert;