import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { 
    MDBContainer, 
    MDBDataTableV5,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBAlert,
    MDBBtnGroup
} from 'mdbreact'

import AdminNav from '../navbar/navbar.component'

const dataExample = [
    { id:1, date:"01/03/2021", hour:"10:00 Hrs", enterprise:"Magna 1", status:"En Proceso" },
    { id:2, date:"02/03/2021", hour:"11:00 Hrs", enterprise:"Magna 2", status:"En Proceso" },
    { id:3, date:"02/03/2021", hour:"12:00 Hrs", enterprise:"Magna 3", status:"En Proceso" },
    { id:4, date:"02/03/2021", hour:"13:00 Hrs", enterprise:"Magna 4", status:"En Proceso" },
    { id:5, date:"02/03/2021", hour:"14:00 Hrs", enterprise:"Magna 5", status:"En Proceso" },
    { id:6, date:"02/03/2021", hour:"15:00 Hrs", enterprise:"Magna 6", status:"En Proceso" },
]

const StreamingRequest = () => {
    const [data, setData] = useState([])

    const [date, setDate] = useState('')

    const handleChange = e => {
        setDate(e.target.value)
    }


    const handleAccept = e => {
        //console.log(e.target.innerText)
        //console.log(e.target.parentNode.parentNode.children[5])
        //e.target.parentNode.parentNode.children[5].innerText = "No"
        e.target.parentNode.parentNode.parentNode.children[4].innerHTML="<div data-test=\"alert\" class=\"alert alert-success\" role=\"alert\">Aceptada</div>"
        alert("Solicitud Aceptada")
    }

    const handleNotAccept = e => {
        //console.log(e.target.innerText)
        //console.log(e.target.parentNode.parentNode.children[5])
        //e.target.parentNode.parentNode.children[5].innerText = "No"
        e.target.parentNode.parentNode.parentNode.children[4].innerHTML="<div data-test=\"alert\" class=\"alert alert-danger\" role=\"alert\">No Aceptada</div>"
        alert("Solicitud No Aceptada")
    }

    const handleSubmit = () => {
        if(document.getElementById("date").value === "Seleccionar"){
            alert("Debe de Seleccionar Una Fecha")
        } else {
            for (var k = 0; k < dataExample.length; k++) {
                //console.log(dataExample[i]['id'])
                if(dataExample[k]['date'] === document.getElementById("date").value){
                    //console.log(k)
                    
                    const request = { 
                        id: dataExample[k]['id'],
                        date: dataExample[k]['date'],
                        hour: dataExample[k]['hour'],
                        enterprise: dataExample[k]['enterprise'],
                        status: <MDBAlert color="primary" >{dataExample[k]['status']}</MDBAlert>,
                        actions: <MDBBtnGroup size="sm">
                                    <MDBBtn color="success" id={dataExample[k]['id']} onClick={handleAccept}>Aceptar</MDBBtn>
                                    <MDBBtn color="danger" id={dataExample[k]['id']} onClick={handleNotAccept}>No Aceptar</MDBBtn>
                                </MDBBtnGroup>,
                    }
                    
                    data.push(request)
                    //console.log(data)
                    setData([...data])
                }
                
            }
        }
    }

    // const handleChange = e => {
    //     const { name, value } = e.target
    //     setData({
    //         ...data,
    //         [name]: value
    //     })
    // }



    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: 'Id',
            field: 'id',
        },
        {
            label: 'Fecha',
            field: 'date',
        },
        {
            label: 'Hora',
            field: 'hour',
        },
        {
            label: 'Empresa',
            field: 'enterprise',
        },
        {
            label: 'Estado',
            field: 'status',
        },
        {
            label: 'Acciones',
            field: 'actions',
        },
        ],
    })

    useEffect(() => {
        setDatatable({        
            ...datatable,
            rows: data
        })

    }, [data])
    const userType = 1

    return(
        <Fragment>
            <Helmet
                title="Agenda de Empresas"
            />
            <AdminNav userType={userType}  />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Solicitudes Streaming</strong></h4>
                    <MDBRow className="mt-5">
                        <MDBCol sm="12" md="6">
                            <div className="form-group">
                                <label htmlFor="date"><strong>Fecha</strong></label>
                                <select 
                                    className="form-control" 
                                    id="date"
                                    name="date"
                                    onChange={handleChange}
                                >
                                    <option>Seleccionar</option>
                                    <option>01/03/2021</option>
                                    <option>02/03/2021</option>
                                    <option>03/03/2021</option>
                                    <option>04/03/2021</option>
                                    <option>05/03/2021</option>
                                </select>
                                <br></br>
                                <button className="btnd btn-b" onClick={handleSubmit}>
                                    Buscar
                                </button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} />
                </MDBContainer>
            </section>
        </Fragment>
    );
}

export default StreamingRequest;