const types = {
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
    LOG_IN_ERROR: 'LOG_IN_ERROR',
    GET_USER: 'GET_USER',
    LOG_OUT: 'LOG_OUT',
    UPDATE_CURRENT_EVENT: 'UPDATE_CURRENT_EVENT',
    UPDATE_CURRENT_EVENT_ERROR: 'UPDATE_CURRENT_EVENT_ERROR',
    LOADING_LOGIN: 'LOADING_LOGIN',
}

export default types