import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import axiosClient from '../../../config/axios'

import FormContainer from '../form-container/form-container.component'
import Alert from '../../alert/alert.component'


const SignUpVisitor = ({ history }) => {
    const [alert, setAlert] = useState(false)
    const [errors, setErrors] = useState()
    const [visitor, addVisitor] = useState({
        name: '',
        email: '',
        password: '',
        number: '',
    })

    const handleChange = e => {
        const { value, name } = e.target

        addVisitor({
            ...visitor,
            [name]: value
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setAlert(false)

        if (name.trim() === '' || email.trim() === '' || password.trim() === '' ||
            whatsapp.trim() === '') {
            setAlert(true)
            return
        } else {
            try {
                setAlert(false)
                const response = await axiosClient.post('/api/users/register-visitor', visitor)
                if (response.status === 201) history.push({ pathname: '/', state: { message: response.data.message } })
            } catch (error) {
                setErrors("Verifica que los datos sean correctos.")
            }
        }
    }

    const { name, email, password, whatsapp, address } = visitor

    return (
        <Fragment>
            <Helmet
                title="Registro visitante"
            />
            <FormContainer>
                <h3 className="mb-4">Registro de visitante</h3>
                { alert ? (
                    <Alert color="danger" message="Todos los campos son obligatorios" />
                ) : (
                    null
                )}

                { errors ? (
                    <Alert color="danger" message={errors} />
                ) : (
                    null
                )}
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="form-group">
                        <input
                            name="name"
                            type="text" 
                            className="form-control"
                            placeholder="Nombre"
                            maxLength="100"
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            name="email"
                            type="email" 
                            className="form-control" 
                            placeholder="Correo"
                            maxLength="150"
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            name="password"
                            type="password" 
                            className="form-control" 
                            placeholder="Contraseña" 
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            name="whatsapp"
                            type="tel" 
                            className="form-control" 
                            placeholder="Whatsapp" 
                            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <input type="submit" value="Registrar" className="btnd btn-b" />
                </form>
            </FormContainer>
        </Fragment>
    );
}
 
export default SignUpVisitor;