import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import { 
    MDBContainer, 
    MDBDataTableV5,
} from 'mdbreact'

import LoginContext from '../../../context/login/login.context'

import AdminNav from '../navbar/navbar.component'
import Alert from '../../alert/alert.component'
import axiosClient from '../../../config/axios'

const Enterprise = () => {
    const [show, setShow] = useState(false);
    const [enterprise, addEnterprise] = useState({
        buyer: '',
        linker: '',
        provider: ''
    })

    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const [alert, setAlert] = useState(false)

    const [sectors, setSectors] = useState({})
    const [loadingSectors, setLoadingSectors] = useState(true)

    const [states, setStates] = useState({})
    const [loadingStates, setLoadingStates] = useState({})

    const [municipalities, setMunicipalities] = useState({})
    const [loadingMunicipalities, setLoadingMunicipalities] = useState(true)

    const [alertSuccess, setAlertSuccess] = useState(false)
    const [loadingNewEnterpise, setLoadingNewEnterprise] = useState(false)

    const handleShow = () => {
        setShow(true)
        setAlertSuccess(false)
    }
     
    const handleClose = () => {
        setShow(false)
        setAlert(false)
    } 

    const handleChange = e => {
        if(e.target.checked === false || e.target.checked === undefined){
            const { name, value } = e.target
            addEnterprise({
                ...enterprise,
                [name]: value
            })
        } else {
            const { name, checked } = e.target
            addEnterprise({
                ...enterprise,
                [name]: checked
            })
        }
    }
    
    const handleSubmit = async e => {
        e.preventDefault()
        setLoadingNewEnterprise(true)
        const { name, sector, state, municipality, buyer, linker, provider } = enterprise

        if(name === "" || sector === "" || state === "" || municipality === "" ||
          (buyer !== true && linker !== true && provider !== true)){
            setAlert(true)
            return 
        } else {
            try {
                setAlert(false)
                
                let enterpriseType = 2

                if (provider !== '') {
                    enterpriseType = 3
                } 

                if(linker !== '') {
                    enterpriseType = 4
                } 
                
                if (buyer !== '' && linker !== '') {
                    enterpriseType = 5
                } 
                
                if (buyer !== '' && provider !== '') {
                    enterpriseType = 6
                } 
                
                if (linker !== '' && provider !== '') {
                    enterpriseType = 7
                } 
                
                if (linker !== '' && provider !== '' && buyer !== '') {
                    enterpriseType = 8
                }
                
                const newEnterprise = {
                    sector: sector,
                    enterpriseType: enterpriseType,
                    municipality: municipality,
                    name: name,
                    eventId: currentEvent
                }

                const response = await axiosClient.post('/api/enterprise/add-enterprise', newEnterprise)
                setAlertSuccess(response.data.message)
                setLoadingNewEnterprise(false)
                setShow(false)
                addEnterprise({})
                getEnterpises()
                addEnterprise({ 
                    buyer: '',
                    linker: '',
                    provider: ''
                })
            } catch (error) {
                console.log(error.response)
            }
        }
    }

    const handleState = async (e) => {
        let stateId = document.getElementById("estado")
        stateId = stateId.options.selectedIndex
        const response = await axiosClient.get('/api/enterprise/get-municipalities', { params: { stateId: stateId } })
        setMunicipalities(response.data.data)
        setLoadingMunicipalities(false)
    }

    const getSectors = async () => {
        const response = await axiosClient.get('/api/enterprise/get-sectors',
                            { params: { registerEnterprise: true, eventId: currentEvent } })
        setSectors(response.data.data)
        setLoadingSectors(false)
    }

    const getStates = async () => {
        const response = await axiosClient.get('/api/enterprise/get-states')
        setStates(response.data.data)
        setLoadingStates(false)
    }

    const getEnterpises = async () => {
        const response = await axiosClient.get('/api/enterprise/get-enterprise',
                                        { params: { admin: true, eventId: currentEvent } }
        )
        setDatatable({
            ...datatable,
            rows: response.data.data
        })
    }

    const [datatable, setDatatable] = useState({        
        columns: [
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Empresa',
                field: 'enterprise',
            },
            {
                label: 'Sector',
                field: 'sector',
            },
            {
                label: 'Tipo de Empresa',
                field: 'enterpriseType',
            },
        ],   
    })

    useEffect(() => {
        getSectors()
        getStates()
        getEnterpises()        
    }, [])

    useEffect(() => {
        getSectors()
        getEnterpises()
    }, [currentEvent])

    return(
        <Fragment>
            <Helmet
                title="Agenda de Empresas"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Empresas</strong></h4>
                    <br></br>
                    <button 
                        className="btnd btn-b mb-3"
                        onClick={handleShow}
                    >
                        Agregar
                    </button>
                    {alertSuccess ? (
                        <Alert color="success" message={alertSuccess}  />
                    ) : null }
                    <MDBDataTableV5 
                        responsive
                        hover 
                        entriesOptions={[5, 20, 25]} 
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable} 
                    />
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <form
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Registrar Empresa
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { alert ? (
                            <Alert color="danger" message="Todos los campos son obligatorios" />
                        ) : null }
                        { loadingNewEnterpise ? (
                            <div className="text-center mb-2">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null }
                        
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    id="nombre" 
                                    className="form-control" 
                                    placeholder="Nombre"
                                    name="name"
                                    onChange={handleChange}
                                ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"><strong>Sector</strong></label>
                                <select 
                                    className="form-control" 
                                    id="sector"
                                    name="sector"
                                    onChange={handleChange}
                                >
                                    <option value="">-- Seleccionar Sector --</option>
                                    { loadingSectors ? null : (
                                        sectors.map((sector) => (
                                            <option 
                                                key={sector.id}
                                                value={sector.id} 
                                            >
                                                {sector.nombre}
                                            </option>
                                    )))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"><strong>Estado</strong></label>
                                <select 
                                    className="form-control" 
                                    id="estado"
                                    name="state"
                                    onChange={handleState}
                                >
                                    <option value="">-- Seleccionar Estado --</option>
                                    { loadingStates ? null : (
                                        states.map((state) => (
                                            <option 
                                                key={state.id}
                                                value={state.id} 
                                            >
                                                {state.nombre}
                                            </option>
                                    )))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"><strong>Municipio</strong></label>
                                <select 
                                    className="form-control" 
                                    id="municipio"
                                    name="municipality"
                                    onChange={handleChange}
                                >
                                    <option value="">-- Seleccionar Municipio --</option>
                                    { loadingMunicipalities ? null : (
                                        municipalities.map((municipality) => (
                                            <option 
                                                key={municipality.id}
                                                value={municipality.id} 
                                            >
                                                {municipality.nombre}
                                            </option>
                                    )))}
                                </select>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="buyer" 
                                    value="" 
                                    name="buyer"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="buyer">Compradora</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="linker" 
                                    value=""
                                    name="linker"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="vinculadora">Vinculadora</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="provider" 
                                    value="" 
                                    name="provider"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="proveedora">Proveedora</label>
                            </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" value="Guardar" className="btnd btn-b" />
                    </Modal.Footer>
                    </form>
                </Modal>
        </Fragment>
    );
}

export default Enterprise;