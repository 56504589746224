import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import LoginContext from '../../../context/login/login.context'

const PrivateRouteUser = ({ component: Component, ...props }) => {
    const loginContext = useContext(LoginContext)
    const { authenticated, loading } = loginContext

    return (
        <Route { ...props }
            render={ props => !authenticated && !loading ? (
                <Redirect to="/#" />
            ) : (
                <Component { ...props } />
            )}
        />
    )
}

export default PrivateRouteUser

