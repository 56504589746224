import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import LoginContext from '../../../context/login/login.context'

const PrivateRouteAdmin = ({ component: Component, ...props }) => {
    const loginContext = useContext(LoginContext)
    const { isAdmin } = loginContext

    // useEffect(() => {
    //     getUser()
    // }, [])

    return (
        <Route { ...props }
            render={ props => !isAdmin ? (
                <Redirect to="/#" />
            ) : (
                <Component { ...props } />
            )}
        />
    )
}

export default PrivateRouteAdmin

