import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import {
    MDBContainer,
    MDBDataTableV5
} from 'mdbreact'

import LoginContext from '../../../context/login/login.context'

import AdminNav from '../navbar/navbar.component'
import Alert from '../../alert/alert.component'
import axiosClient from '../../../config/axios'

const Sectors = () => {
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState(false)
    const [alertSuccess, setAlertSucess] = useState(false)
    const [loading, setLoading] = useState(false)

    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setAlertSucess(false)
        setShow(true);
    } 

    const [sector, addSector] = useState({
        name: '',
        eventId: ''
    })

    const handleChange = e => {
        const { name, value } = e.target
        addSector({
            ...sector,
            [name]: value
        })
    }

    const [data, setData] = useState([])
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (sector.name.trim() === '' || sector.eventType === '') {
            setAlert(true)
            return;
        } else {
            try {
                setLoading(true)
                setAlert(false)
                sector.eventId = currentEvent
                const response = await axiosClient.post('/api/enterprise/add-sector', sector)
                setAlertSucess(response.data.message)
                setShow(false)
                setLoading(false)
                getSectors()
            } catch (error) {
                console.log(error.response)
            }
        }
    }
    
    const getSectors = async () => {
        const response = await axiosClient.get('/api/enterprise/get-sectors',
                                            { params: { eventId: currentEvent } }                                    
        )
        setDatatable({
            ...datatable,
            rows: response.data.data
        })
    }

    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: 'Sector',
                field: 'nombre',
            },
            {
                label: 'Num. Empresas',
                field: 'id',
            },
        ],
    })

    useEffect(()  => {
        getSectors()
        // eslint-disable-next-line
    }, [currentEvent])

    // useEffect(() => {
    //     getSectors()
    //     // eslint-disable-next-lin
    // }, [currentEvent])

    return (
        <Fragment>
            <Helmet
                title="Sectores"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Sectores</strong></h4>
                    <button className="btnd btn-b mb-3" onClick={handleShow}>
                        Agregar
                    </button>
                    { alertSuccess ? (
                        <Alert color="success" message={alertSuccess} />
                    ) : null }
                    <MDBDataTableV5
                        responsive
                        hover 
                        entriesOptions={[5, 20, 25]}
                        entries={5} 
                        pagesAmount={4} 
                        data={datatable} 
                    />
                </MDBContainer>
            </section>
            <Modal show={show} onHide={handleClose}>
                <form
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Agregar sector
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        { alert ? (
                            <Alert color="danger" message="Ingresa nombre."  />
                        ) : null }
                        { loading ? (
                            <div className="text-center mb-2">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null }
                        <div className="form-group">
                            <input 
                                type="text" 
                                name="name" 
                                className="form-control" 
                                placeholder="Nombre" 
                                onChange={handleChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input 
                            type="submit" 
                            value="Agregar"
                            className="btnd btn-b"
                        />
                    </Modal.Footer>
                    </form>
                </Modal>
        </Fragment>
    );
}
 
export default Sectors;
