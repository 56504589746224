import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-bootstrap'
import { 
    MDBContainer, 
    MDBDataTableV5,
    MDBRow,
    MDBCol,
    MDBAlert
} from 'mdbreact'

import AdminNav from '../navbar/navbar.component'

const UserEvent = () => {

    const [show, setShow] = useState(false)
    const [userEvent, addUserEvent] = useState({})

    const handleChange = e => {
        const { name, value } = e.target
        addUserEvent({
            ...userEvent,
            [name]: value
        })
    }

    const [data, setData] = useState([])
    
    const handleSubmit = () => {
        var idNew = document.getElementById('id').value
        const { id, user, event } = userEvent
        if(Object.keys(userEvent).length < 2){
            alert("Debe Ingresar todos los datos.")
            return;
        } else {

            if(user === "" || user === "Seleccionar"){
                alert("Debe Seleccionar un Usuario.")
                return;
            } else if(event === "" || event === "Seleccionar"){
                alert("Debe Seleccionar un Evento.")
                return;
            } else{
                var enterpriseNew = ""
                var userNew = ""
                userNew = user.split('-',1)
                enterpriseNew = user.split('-',2)
                const newUserEvent = { 
                    id: idNew,
                    event: event,
                    user: userNew,
                    enterprise: enterpriseNew[1],
                    rol: <MDBAlert color="success" >Administrador</MDBAlert>
                }
                setData([...data, newUserEvent])
                setShow(false)
            }
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: 'Id',
            field: 'id',
        },
        {
            label: 'Evento',
            field: 'event',
        },
        {
            label: 'Usuario',
            field: 'user',
        },
        {
            label: 'Empresa',
            field: 'enterprise',
        },
        {
            label: 'Rol',
            field: 'rol',
        },
        ],
    })

    useEffect(() => {
        setDatatable({        
            ...datatable,
            rows: data
        })

    }, [data])

    return(
        <Fragment>
            <Helmet
                title="Inscripciones a Eventos"
            />
            <AdminNav />
            <section className="mt-5">
                <MDBContainer>
                    <h4><strong>Inscripciones a Eventos</strong></h4>
                    <br></br>
                    <button className="btnd btn-b" onClick={handleShow}>
                        Agregar
                    </button>
                    <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} />
                </MDBContainer>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Inscripcion a Evento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <MDBRow mt="4">
                            <MDBCol sm="12">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        readonly="readonly" 
                                        name="id" 
                                        id="id"
                                        className="form-control" 
                                        value={data.length+1}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1"><strong>Usuario</strong></label>
                                    <select 
                                        className="form-control" 
                                        id="user"
                                        name="user"
                                        onChange={handleChange}
                                    >
                                        <option>Seleccionar</option>
                                        <option>Usuario 1 - Empresa 1</option>
                                        <option>Usuario 2 - Empresa 2</option>
                                        <option>Usuario 3 - Empresa 3</option>
                                        <option>Usuario 4 - Empresa 4</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1"><strong>Evento</strong></label>
                                    <select 
                                        className="form-control" 
                                        id="event"
                                        name="event"
                                        onChange={handleChange}    
                                    >
                                        <option>Seleccionar</option>
                                        <option>Agro</option>
                                        <option>B2B</option>
                                        <option>Exposalud</option>
                                        <option>Enlaces</option>
                                    </select>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnd btn-b" onClick={handleSubmit}>
                        Agregar
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default UserEvent;