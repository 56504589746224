/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal } from "react-bootstrap";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";

import Navbar from "../navbar/navbar.component";
import UploadImage from "../../upload-images/upload-images.component";
import LoginContext from "../../../context/login/login.context";

import VerboseAlert from "../../verbose-alert/verbose-alert.component";
import Alert from "../../alert/alert.component";
import axiosClient from "../../../config/axios";

import { cleanImage } from "../../../config/helper";

export const UploadStandData = ({ history }) => {
  const config = [
    "bold",
    "italic",
    "NumberedList",
    "BulletedList",
    "Blockquote",
    "Link",
  ];

  const [image, setImage] = useState({});
  const [data, setData] = useState({
    catalogue: "",
    facebook: "",
    twitter: "",
    video: "",
    web: "",
  });

  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState();

  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState(false);

  const [standData, setStandData] = useState({});

  const loginContext = useContext(LoginContext);
  const { user } = loginContext;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const getImage = async () => {
    const response = await axiosClient.get("/api/users/user-image", {
      params: { id: user.id },
    });
    let logo = response.data.data[0].logo;
    response.data.data[0].logo = cleanImage(logo);
    setImage(response.data.data[0].logo);
  };

  const setParentImages = (image) => {
    setImages(image);
  };

  const setParentFile = (file) => {
    setFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { facebook, twitter, video, web } = data;

    if (description === "") {
      setAlert("Ingresa una descripción de tu empresa.");
      return;
    }

    let checkImages = [];

    for (let i = 0; i < images.length; i++) {
      checkImages.push(images[i]);
    }

    if (checkImages.length === 0) {
      setAlert("Ingresa al menos una imagen.");
      return;
    }

    // try{
    setLoading(true);
    const dataNew = new FormData();

    if (document.getElementById("pdf").value !== "") {
      let pdf = document.getElementById("pdf");
      let pdfName = pdf.files[0].name;
      let pdfSplit = pdfName.split(".");
      let extention = pdfSplit[pdfSplit.length - 1];

      if (extention.toLowerCase() === "pdf") {
        dataNew.append("catalogue", pdf.files[0]);
      } else {
        setAlert("Formato de archivo debe de ser .pdf");
        return;
      }
    }

    dataNew.append("description", description);
    dataNew.append("facebook", facebook);
    dataNew.append("twitter", twitter);
    dataNew.append("video", video);
    dataNew.append("web", web);
    dataNew.append("image1", images[0]);
    dataNew.append("image2", images[1]);
    dataNew.append("image3", images[2]);
    dataNew.append("image4", images[3]);
    dataNew.append("image5", images[4]);
    dataNew.append("enterpriseId", user.id_empresa);

    const response = await axiosClient.patch("/api/users/add-stand", dataNew);
    if (response.status === 200) {
      setLoading(false);
      history.push(`/stand/${response.data.data[0].slug}`);
    }
    // } catch(error) {
    //     console.log(error.response)
    // }
  };

  const isStandCompleted = async () => {
    try {
      const response = await axiosClient.get(
        "/api/users/get-is-stand-completed",
        { params: { id_empresa: user.id_empresa } }
      );
      if (response.data.data[0].estado === 3) {
        history.push(`/stand/${response.data.data[0].slug}`);
      } else {
        setStandData(response.data.data[0]);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (user.id_tipo_empresa === 1) history.push("/stands");
    isStandCompleted();
    getImage();
  }, [user]);

  if (standData.estado === 1) {
    return (
      <Fragment>
        <Helmet title="Stand virtual" />
        <Navbar />
        <section className="mt-5">
          <MDBContainer>
            <VerboseAlert
              color="success"
              title="Stand en proceso de revisión."
              content="Su stand está en proceso de revisión le notificaremos a su correo electrónico registrado al quedar aprobado o en su caso cualquier comentario para aclaración.
                        Una vez aprobado podrás revisar tu stand en el apartado de stands."
              footer="Gracias por la espera."
            />
          </MDBContainer>
        </section>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Helmet title="Stand virtual" />
        <Navbar />
        <section className="mt-5">
          <MDBContainer>
            <form>
              {alert ? <Alert color="danger" message={alert} /> : null}

              {standData.comentarios ? (
                <h4 className="mb-5">
                  Comentarios: <small>{standData.comentarios}</small>
                </h4>
              ) : null}

              <MDBRow>
                <MDBCol sm="12" md="4" className="mb-3">
                  <img width="100%" height="auto" src={image} alt={image} />
                </MDBCol>
                <MDBCol className="" sm="12" md="8">
                  <div className="form-group">
                    <CKEditor
                      name="description"
                      editor={ClassicEditor}
                      config={{
                        toolbar: config,
                      }}
                      data="<p>Descripción de tu empresa</p>"
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-4">
                <MDBCol sm="12" md="6">
                  <div className="form-group">
                    <div className="custom-file">
                      <label htmlFor="pdf">Catálogo</label>
                      <input type="file" id="pdf" name="pdf" />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="video"
                      className="form-control"
                      placeholder="Video de presentación (Link de YouTube)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="web"
                      className="form-control"
                      placeholder="Sitio web"
                      onChange={handleChange}
                    />
                  </div>
                </MDBCol>
                <MDBCol sm="12" md="6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="facebook"
                      className="form-control"
                      placeholder="Facebook (Link de Facebook)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="twitter"
                      className="form-control"
                      placeholder="Twitter (Link de Twitter)"
                      onChange={handleChange}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="12">
                  <UploadImage
                    setParentImages={setParentImages}
                    maxFiles={5}
                    stand={true}
                  />
                </MDBCol>
              </MDBRow>
            </form>

            {description !== "" && images !== undefined ? (
              <button className="btnd btn-b" onClick={() => setShow(true)}>
                Previsualizar
              </button>
            ) : null}
          </MDBContainer>
        </section>
        <Modal
          size="lg"
          show={show}
          onHide={() => setShow(false)}
          className="admin-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Previsualizar stand
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            {data.video ? (
              <iframe
                title="video"
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${data.video.slice(32)}`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : null}
            <br />
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {data.facebook ? (
              <a href={data.facebook} target="_blank" rel="noreferrer">
                <MDBIcon className="mx-2" size="2x" fab icon="facebook-f" />
              </a>
            ) : null}
            {data.twitter ? (
              <a href={data.twitter} target="_blank" rel="noreferrer">
                <MDBIcon size="2x" fab icon="twitter" />
              </a>
            ) : null}
            {data.web ? (
              <a href={data.web} rel="noreferrer" target="_blank">
                {data.web}
              </a>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <button type="submit" className="btnd btn-b " disabled>
                Guardando
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            ) : (
              <button className="btnd btn-b" onClick={handleSubmit}>
                Guardar
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
};

export default UploadStandData;
