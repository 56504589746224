import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Form } from 'react-bootstrap'
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBDataTableV5
} from 'mdbreact'

import AdminNav from '../navbar/navbar.component'

const dataExample = [
    { id:1, event:"Agro", date:"01/03/2021", hour:"10:00 Hrs", status:2},
    { id:2, event:"Agro", date:"02/03/2021", hour:"11:00 Hrs", status:2},
    { id:3, event:"Enlaces", date:"02/03/2021", hour:"12:00 Hrs", status:2},
    { id:4, event:"Salud", date:"03/03/2021", hour:"13:00 Hrs", status:2},
    { id:5, event:"Salud", date:"03/03/2021", hour:"14:00 Hrs", status:2},
    { id:6, event:"Salud", date:"03/03/2021", hour:"15:00 Hrs", status:2},
]

const RegisterScheduleStreaming = () => {
    const [dataEx, setDataEx] = useState([])

    const [dataExa, setDataExample] = useState([])
    const handleSubmit = e => {
        setDataExample([])
        if(document.getElementById("event").value === "Seleccionar" || document.getElementById("date").value === "Seleccionar"){
            alert("Debe Seleccionar un Evento y Fecha")
        } else {

            for (var n = 0; n < dataExa.length; n++) {
                dataExa.splice(n)
            }

            for (var l = 0; l < dataExample.length; l++) {
                //console.log(dataExample[i]['id'])
                if(dataExample[l]['event'] === document.getElementById("event").value && dataExample[l]['date'] === document.getElementById("date").value){
                    //console.log(l)
                    
                    const requestStreaming = { 
                        id: dataExample[l]['id'],
                        hour: dataExample[l]['hour'],
                        status: <input type="checkbox"></input>
                    }
                    
                    dataExa.push(requestStreaming)
                    //console.log(data)
                    setDataExample([...dataExa])
                }
                
            }
        //console.log(dataExa)
        }
    }

    

    const [data, setData] = useState({})
    

    const handleChange = e => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

        if(e.target.name === "event"){
            const select = document.getElementById("date")
            let dataEx = [];

            for (let j = select.options.length; j >= 0; j--) {
                select.remove(j);
            }
            const optionSelec = document.createElement('option')
            const valorSelect = "Seleccionar"
            optionSelec.value = valorSelect;
            optionSelec.text = valorSelect;
            select.appendChild(optionSelec);

            for (var i = 0; i < dataExample.length; i++) {
                //console.log(dataExample[i]['id'])
                if(dataExample[i]['event'] === document.getElementById("event").value){
                    dataEx.push(dataExample[i]["date"])
                }
            }
            const dataArr = new Set(dataEx);
            let result = [...dataArr];
            //console.log(result)
            for (let k = 0; k < result.length; k++) {
                //console.log(result[0])
                const option = document.createElement('option')
                const valor = result[k]
                option.value = valor;
                option.text = valor;
                select.appendChild(option);
            }
        
        }
    }

    const [schedule, setSchedule] = useState({})
    const handleSchedule = e => {
        const { id, checked } = e.target
        setSchedule({ ...schedule, [id]: checked })
    }


    const [datatable, setDatatable] = useState({        
        columns: [
        {
            label: 'Id',
            field: 'id',
        },
        {
            label: 'Hora',
            field: 'hour',
        },
        {
            label: 'Disponible',
            field: 'status',
        }

    ],
        
    })

    useEffect(() => {
        setDatatable({        
            ...datatable,
            rows: dataExa
        })

    }, [dataExa])


    return (
        <Fragment>
            <Helmet 
                title="Registrar agenda de transmisiones"
            />
            <AdminNav userType={1} />
            <section className="mt-5">
                <div className="container">
                    <h4><strong>Creación de agenda de transmisiones</strong></h4>
                </div>
            </section>
            <section>
                <MDBContainer className="mt-5">
                    <MDBRow>
                        <MDBCol sm="12" md="5">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"><strong>Evento</strong></label>
                                <select 
                                    className="form-control" 
                                    id="event"
                                    name="event"
                                    onChange={handleChange}
                                >
                                    <option defaultValue>Seleccionar</option>
                                    <option>Agro</option>
                                    <option>Enlaces</option>
                                    <option>Salud</option>
                                </select>
                            </div>
                        </MDBCol>
                        <MDBCol sm="12" md="5">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1"><strong>Fechas</strong></label>
                                <select 
                                    className="form-control" 
                                    id="date"
                                    name="date"
                                    onChange={handleChange}
                                >
                                    <option defaultValue>Seleccionar</option>
                                </select>
                            </div>
                            
                        </MDBCol>
                        <MDBCol sm="12" md="2">
                            <button  className="btnd btn-b mt-4" onClick={handleSubmit}>Buscar</button>
                        </MDBCol>
                    </MDBRow>
                    <MDBContainer>
                        <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable}/>
                    </MDBContainer>
                </MDBContainer>
            </section>
        </Fragment>

    );
}
 
export default RegisterScheduleStreaming;