import React, {useContext, Fragment, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardTitle
} from 'mdbreact'

import LoginContext from '../../../context/login/login.context'

import './stands.styles.css'
import Navbar from '../navbar/navbar.component'
import axiosClient from '../../../config/axios'
import { cleanImage } from '../../../config/helper'

const StandListing = () => {
    const [sectors, setSectors] = useState({})
    const [loadingSectors, setLoadingSectors] = useState(true)
    const [enterprises, setEnterprises] = useState()

    const loginContext = useContext(LoginContext)
    const { currentEvent } = loginContext

    const handleChange = async (e) => {
        const { options } = e.target
        const id = options[options.selectedIndex].value
        console.log(id)
        let response = await axiosClient.get('/api/enterprise/get-enterprise',
                                                 { params: { id: id } })
        response = response.data.data   

        for(let i = 0; i < response.length; i++) {
            let logo = response[i].logo
            response[i].logo = cleanImage(logo)
        }
        setEnterprises(response)
    }

    const getSectors = async () => {
        const response = await axiosClient.get('/api/enterprise/get-sectors',
                                        { params: { flagStands: true, eventId: currentEvent } })
        setSectors(response.data.data)
        setLoadingSectors(false)
    }

    useEffect(() => {
        getSectors()
        setEnterprises()
    }, [currentEvent])

    return (
        <Fragment>
            <Navbar />
            <section className="mt-5">
            <MDBContainer>
                <MDBRow>
                    <MDBCol sm="12" md="12">
                        <div className="form-group">
                            <center><h2><strong>Stand virtual</strong></h2></center>
                        </div> 
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="12" md="6">
                        <label htmlFor="exampleFormControlSelect1"><strong>Sector</strong></label>
                        <select 
                            name="sector"
                            className="form-control"
                            id="sectors"
                            onChange={handleChange}
                        >
                        <option value="">-- Selecciona un sector --</option>
                        {loadingSectors ? null : (
                            sectors.map(sector => (
                                <option
                                    key={sector.id}
                                    value={sector.id}
                                >
                                    {sector.nombre}
                                </option>
                            ))
                        )}
                        </select>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-5">
                {enterprises ? (
                    enterprises.map(enterprise => (
                        <MDBCol 
                            key={enterprise.id}
                            className="mb-3" 
                            sm="12" 
                            md="4"
                        >
                            <MDBCard className="enterprises">
                                <MDBCardImage 
                                    className="img-fluid"
                                    src={enterprise.logo}
                                    width="350px"
                                    height="200px"
                                />
                                <MDBCardBody>
                                    <MDBCardTitle>{enterprise.nombre}</MDBCardTitle>
                                    <Link
                                        className="btnd btn-b"
                                        to={`/stand/${enterprise.slug}`}
                                    >
                                        Visitar
                                    </Link>
                                    </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    ))
                ) : null}
                    
                </MDBRow>
            </MDBContainer>
            </section>
        </Fragment>
    );
}
 
export default StandListing;